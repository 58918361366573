$vertical-menu-width: 72px;
$mat-toolbar-height-desktop: 15px;
$mat-toolbar-height-mobile: 73px;
$header-logo-heigth: 56px;
$sidenav-sm-desktop-width: 100vw;
$sidenav-md-desktop-width: 100vw;
$sidenav-lg-desktop-width: 100vw;
$sidenav-mobile-width: 100vw;
$project-menu-width: 220px;
$mat-paginator-height: 72px;
$header-height-desktop: 129px;
$header-height-mobile: 80px;

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
.header {
  position: relative;
  width: 100%;
  padding-top: 20px;
  background-color: $app-black;
  border-bottom: 1px solid #4a4a4a;
  text-align: center;

  &--sidenav {
    border-bottom: none;
  }
}

.logo {
  height: auto;
  max-width: 100%;
}

.mat-icon-button {
  color: $app-white !important;
}

@include respond-to(XSmall) {
  .header {
    border-bottom: none !important;
    padding-bottom: 10px;
  }
  .logo {
    height: auto;
    max-width: 71%;
    padding-top: 10px;
  }
}

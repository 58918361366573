.mat-badge-content {
  font-weight: 600;
  font-size: 12px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-badge-small .mat-badge-content {
  font-size: 9px;
}

.mat-badge-large .mat-badge-content {
  font-size: 24px;
}

.mat-h1,
.mat-headline,
.mat-typography .mat-h1,
.mat-typography .mat-headline,
.mat-typography h1 {
  font: 400 24px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h2,
.mat-title,
.mat-typography .mat-h2,
.mat-typography .mat-title,
.mat-typography h2 {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h3,
.mat-subheading-2,
.mat-typography .mat-h3,
.mat-typography .mat-subheading-2,
.mat-typography h3 {
  font: 400 16px / 28px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h4,
.mat-subheading-1,
.mat-typography .mat-h4,
.mat-typography .mat-subheading-1,
.mat-typography h4 {
  font: 400 15px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h5,
.mat-typography .mat-h5,
.mat-typography h5 {
  font: 400 calc(14px * 0.83) / 20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}

.mat-h6,
.mat-typography .mat-h6,
.mat-typography h6 {
  font: 400 calc(14px * 0.67) / 20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}

.mat-body-strong,
.mat-body-2,
.mat-typography .mat-body-strong,
.mat-typography .mat-body-2 {
  font: 500 14px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-body,
.mat-body-1,
.mat-typography .mat-body,
.mat-typography .mat-body-1,
.mat-typography {
  font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.mat-body p,
.mat-body-1 p,
.mat-typography .mat-body p,
.mat-typography .mat-body-1 p,
.mat-typography p {
  margin: 0 0 12px;
}

.mat-small,
.mat-caption,
.mat-typography .mat-small,
.mat-typography .mat-caption {
  font: 400 12px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-display-4,
.mat-typography .mat-display-4 {
  font: 300 112px / 112px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.05em;
  margin: 0 0 56px;
}

.mat-display-3,
.mat-typography .mat-display-3 {
  font: 400 56px / 56px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.02em;
  margin: 0 0 64px;
}

.mat-display-2,
.mat-typography .mat-display-2 {
  font: 400 45px / 48px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.005em;
  margin: 0 0 64px;
}

.mat-display-1,
.mat-typography .mat-display-1 {
  font: 400 34px / 40px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 64px;
}

.mat-bottom-sheet-container {
  font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-button, .mat-raised-button, .mat-icon-button, .mat-stroked-button,
.mat-flat-button, .mat-fab, .mat-mini-fab {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-button-toggle {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-card {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-card-title {
  font-size: 24px;
  font-weight: 500;
}

.mat-card-header .mat-card-title {
  font-size: 20px;
}

.mat-card-subtitle,
.mat-card-content {
  font-size: 14px;
}

.mat-checkbox {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-checkbox-layout .mat-checkbox-label {
  line-height: 24px;
}

.mat-chip {
  font-size: 14px;
  font-weight: 500;
}
.mat-chip .mat-chip-trailing-icon.mat-icon,
.mat-chip .mat-chip-remove.mat-icon {
  font-size: 18px;
}

.mat-table {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-header-cell {
  font-size: 12px;
  font-weight: 500;
}

.mat-cell, .mat-footer-cell {
  font-size: 14px;
}

.mat-calendar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-calendar-body {
  font-size: 13px;
}

.mat-calendar-body-label,
.mat-calendar-period-button {
  font-size: 14px;
  font-weight: 500;
}

.mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400;
}

.mat-dialog-title {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-expansion-panel-header {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 15px;
  font-weight: 400;
}

.mat-expansion-panel-content {
  font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-form-field {
  font-size: inherit;
  font-weight: 400;
  line-height: 1.125;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-form-field-wrapper {
  padding-bottom: 1.34375em;
}

.mat-form-field-prefix .mat-icon,
.mat-form-field-suffix .mat-icon {
  font-size: 150%;
  line-height: 1.125;
}
.mat-form-field-prefix .mat-icon-button,
.mat-form-field-suffix .mat-icon-button {
  height: 1.5em;
  width: 1.5em;
}
.mat-form-field-prefix .mat-icon-button .mat-icon,
.mat-form-field-suffix .mat-icon-button .mat-icon {
  height: 1.125em;
  line-height: 1.125;
}

.mat-form-field-infix {
  padding: 0.5em 0;
  border-top: 0.84375em solid transparent;
}

.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-label-wrapper {
  top: -0.84375em;
  padding-top: 0.84375em;
}

.mat-form-field-label {
  top: 1.34375em;
}

.mat-form-field-underline {
  bottom: 1.34375em;
}

.mat-form-field-subscript-wrapper {
  font-size: 75%;
  margin-top: 0.6666666667em;
  top: calc(100% - 1.7916666667em);
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.4375em 0;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.001px);
  width: 133.3333333333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00101px);
  width: 133.3333433333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00102px);
  width: 133.3333533333%;
}
.mat-form-field-appearance-legacy .mat-form-field-label {
  top: 1.28125em;
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: 0.5416666667em;
  top: calc(100% - 1.6666666667em);
}

@media print {
  .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28122em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28121em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.2812em) scale(0.75);
  }
}
.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.25em 0 0.75em 0;
}
.mat-form-field-appearance-fill .mat-form-field-label {
  top: 1.09375em;
  margin-top: -0.5em;
}
.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1em 0 1em 0;
}
.mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.84375em;
  margin-top: -0.25em;
}
.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-grid-tile-header,
.mat-grid-tile-footer {
  font-size: 14px;
}
.mat-grid-tile-header .mat-line,
.mat-grid-tile-footer .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-grid-tile-header .mat-line:nth-child(n+2),
.mat-grid-tile-footer .mat-line:nth-child(n+2) {
  font-size: 12px;
}

input.mat-input-element {
  margin-top: -0.0625em;
}

.mat-menu-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
}

.mat-radio-button {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-select {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-select-trigger {
  height: 1.125em;
}

.mat-slide-toggle-content {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-slider-thumb-label-text {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}

.mat-stepper-vertical, .mat-stepper-horizontal {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-step-label {
  font-size: 14px;
  font-weight: 400;
}

.mat-step-sub-label-error {
  font-weight: normal;
}

.mat-step-label-error {
  font-size: 14px;
}

.mat-step-label-selected {
  font-size: 14px;
  font-weight: 500;
}

.mat-tab-group {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-tab-label, .mat-tab-link {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-toolbar,
.mat-toolbar h1,
.mat-toolbar h2,
.mat-toolbar h3,
.mat-toolbar h4,
.mat-toolbar h5,
.mat-toolbar h6 {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0;
}

.mat-tooltip {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.mat-tooltip-handset {
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.mat-list-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-list-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-list-base .mat-list-item {
  font-size: 16px;
}
.mat-list-base .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-list-option {
  font-size: 16px;
}
.mat-list-base .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-list-base[dense] .mat-list-item {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}

.mat-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 16px;
}

.mat-optgroup-label {
  font: 500 14px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-simple-snackbar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
}

.mat-simple-snackbar-action {
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
}

.mat-tree {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-tree-node,
.mat-nested-tree-node {
  font-weight: 400;
  font-size: 14px;
}

.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-backdrop-noop-animation {
  transition: none;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start {
  /*!*/
}
@keyframes cdk-text-field-autofill-end {
  /*!*/
}
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}

.mat-mdc-focus-indicator {
  position: relative;
}

.app-theme .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.app-theme .mat-option {
  color: rgba(0, 0, 0, 0.87);
}
.app-theme .mat-option:hover:not(.mat-option-disabled), .app-theme .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.app-theme .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.app-theme .mat-option.mat-active {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}
.app-theme .mat-option.mat-option-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.app-theme .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: black;
}
.app-theme .mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #e927c1;
}
.app-theme .mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #f44336;
}
.app-theme .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.54);
}
.app-theme .mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38);
}
.app-theme .mat-pseudo-checkbox {
  color: rgba(0, 0, 0, 0.54);
}
.app-theme .mat-pseudo-checkbox::after {
  color: #fafafa;
}
.app-theme .mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}
.app-theme .mat-primary .mat-pseudo-checkbox-checked,
.app-theme .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: black;
}
.app-theme .mat-pseudo-checkbox-checked,
.app-theme .mat-pseudo-checkbox-indeterminate,
.app-theme .mat-accent .mat-pseudo-checkbox-checked,
.app-theme .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #e927c1;
}
.app-theme .mat-warn .mat-pseudo-checkbox-checked,
.app-theme .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #f44336;
}
.app-theme .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.app-theme .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}
.app-theme .mat-app-background, .app-theme.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.app-theme .mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app-theme .mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.app-theme .mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app-theme .mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.app-theme .mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app-theme .mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.app-theme .mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.app-theme .mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.app-theme .mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.app-theme .mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.app-theme .mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.app-theme .mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.app-theme .mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.app-theme .mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.app-theme .mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.app-theme .mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.app-theme .mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.app-theme .mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.app-theme .mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.app-theme .mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.app-theme .mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.app-theme .mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.app-theme .mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.app-theme .mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.app-theme .mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.app-theme .mat-autocomplete-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.app-theme .mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app-theme .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.app-theme .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: rgba(0, 0, 0, 0.87);
}
.app-theme .mat-badge {
  position: relative;
}
.app-theme .mat-badge.mat-badge {
  overflow: visible;
}
.app-theme .mat-badge-hidden .mat-badge-content {
  display: none;
}
.app-theme .mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}
.app-theme .ng-animate-disabled .mat-badge-content,
.app-theme .mat-badge-content._mat-animation-noopable {
  transition: none;
}
.app-theme .mat-badge-content.mat-badge-active {
  transform: none;
}
.app-theme .mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.app-theme .mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.app-theme .mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.app-theme .mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .app-theme .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.app-theme .mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .app-theme .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.app-theme .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .app-theme .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.app-theme .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .app-theme .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}
.app-theme .mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.app-theme .mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.app-theme .mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.app-theme .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .app-theme .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.app-theme .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .app-theme .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.app-theme .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .app-theme .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.app-theme .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .app-theme .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}
.app-theme .mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.app-theme .mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.app-theme .mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.app-theme .mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .app-theme .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.app-theme .mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .app-theme .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.app-theme .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .app-theme .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.app-theme .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .app-theme .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}
.app-theme .mat-badge-content {
  color: white;
  background: black;
}
.cdk-high-contrast-active .app-theme .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.app-theme .mat-badge-accent .mat-badge-content {
  background: #e927c1;
  color: white;
}
.app-theme .mat-badge-warn .mat-badge-content {
  color: white;
  background: #f44336;
}
.app-theme .mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38);
}
.app-theme .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.app-theme .mat-button, .app-theme .mat-icon-button, .app-theme .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.app-theme .mat-button.mat-primary, .app-theme .mat-icon-button.mat-primary, .app-theme .mat-stroked-button.mat-primary {
  color: black;
}
.app-theme .mat-button.mat-accent, .app-theme .mat-icon-button.mat-accent, .app-theme .mat-stroked-button.mat-accent {
  color: #e927c1;
}
.app-theme .mat-button.mat-warn, .app-theme .mat-icon-button.mat-warn, .app-theme .mat-stroked-button.mat-warn {
  color: #f44336;
}
.app-theme .mat-button.mat-primary.mat-button-disabled, .app-theme .mat-button.mat-accent.mat-button-disabled, .app-theme .mat-button.mat-warn.mat-button-disabled, .app-theme .mat-button.mat-button-disabled.mat-button-disabled, .app-theme .mat-icon-button.mat-primary.mat-button-disabled, .app-theme .mat-icon-button.mat-accent.mat-button-disabled, .app-theme .mat-icon-button.mat-warn.mat-button-disabled, .app-theme .mat-icon-button.mat-button-disabled.mat-button-disabled, .app-theme .mat-stroked-button.mat-primary.mat-button-disabled, .app-theme .mat-stroked-button.mat-accent.mat-button-disabled, .app-theme .mat-stroked-button.mat-warn.mat-button-disabled, .app-theme .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.app-theme .mat-button.mat-primary .mat-button-focus-overlay, .app-theme .mat-icon-button.mat-primary .mat-button-focus-overlay, .app-theme .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: black;
}
.app-theme .mat-button.mat-accent .mat-button-focus-overlay, .app-theme .mat-icon-button.mat-accent .mat-button-focus-overlay, .app-theme .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #e927c1;
}
.app-theme .mat-button.mat-warn .mat-button-focus-overlay, .app-theme .mat-icon-button.mat-warn .mat-button-focus-overlay, .app-theme .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #f44336;
}
.app-theme .mat-button.mat-button-disabled .mat-button-focus-overlay, .app-theme .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .app-theme .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.app-theme .mat-button .mat-ripple-element, .app-theme .mat-icon-button .mat-ripple-element, .app-theme .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}
.app-theme .mat-button-focus-overlay {
  background: black;
}
.app-theme .mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(0, 0, 0, 0.12);
}
.app-theme .mat-flat-button, .app-theme .mat-raised-button, .app-theme .mat-fab, .app-theme .mat-mini-fab {
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
}
.app-theme .mat-flat-button.mat-primary, .app-theme .mat-raised-button.mat-primary, .app-theme .mat-fab.mat-primary, .app-theme .mat-mini-fab.mat-primary {
  color: white;
}
.app-theme .mat-flat-button.mat-accent, .app-theme .mat-raised-button.mat-accent, .app-theme .mat-fab.mat-accent, .app-theme .mat-mini-fab.mat-accent {
  color: white;
}
.app-theme .mat-flat-button.mat-warn, .app-theme .mat-raised-button.mat-warn, .app-theme .mat-fab.mat-warn, .app-theme .mat-mini-fab.mat-warn {
  color: white;
}
.app-theme .mat-flat-button.mat-primary.mat-button-disabled, .app-theme .mat-flat-button.mat-accent.mat-button-disabled, .app-theme .mat-flat-button.mat-warn.mat-button-disabled, .app-theme .mat-flat-button.mat-button-disabled.mat-button-disabled, .app-theme .mat-raised-button.mat-primary.mat-button-disabled, .app-theme .mat-raised-button.mat-accent.mat-button-disabled, .app-theme .mat-raised-button.mat-warn.mat-button-disabled, .app-theme .mat-raised-button.mat-button-disabled.mat-button-disabled, .app-theme .mat-fab.mat-primary.mat-button-disabled, .app-theme .mat-fab.mat-accent.mat-button-disabled, .app-theme .mat-fab.mat-warn.mat-button-disabled, .app-theme .mat-fab.mat-button-disabled.mat-button-disabled, .app-theme .mat-mini-fab.mat-primary.mat-button-disabled, .app-theme .mat-mini-fab.mat-accent.mat-button-disabled, .app-theme .mat-mini-fab.mat-warn.mat-button-disabled, .app-theme .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.app-theme .mat-flat-button.mat-primary, .app-theme .mat-raised-button.mat-primary, .app-theme .mat-fab.mat-primary, .app-theme .mat-mini-fab.mat-primary {
  background-color: black;
}
.app-theme .mat-flat-button.mat-accent, .app-theme .mat-raised-button.mat-accent, .app-theme .mat-fab.mat-accent, .app-theme .mat-mini-fab.mat-accent {
  background-color: #e927c1;
}
.app-theme .mat-flat-button.mat-warn, .app-theme .mat-raised-button.mat-warn, .app-theme .mat-fab.mat-warn, .app-theme .mat-mini-fab.mat-warn {
  background-color: #f44336;
}
.app-theme .mat-flat-button.mat-primary.mat-button-disabled, .app-theme .mat-flat-button.mat-accent.mat-button-disabled, .app-theme .mat-flat-button.mat-warn.mat-button-disabled, .app-theme .mat-flat-button.mat-button-disabled.mat-button-disabled, .app-theme .mat-raised-button.mat-primary.mat-button-disabled, .app-theme .mat-raised-button.mat-accent.mat-button-disabled, .app-theme .mat-raised-button.mat-warn.mat-button-disabled, .app-theme .mat-raised-button.mat-button-disabled.mat-button-disabled, .app-theme .mat-fab.mat-primary.mat-button-disabled, .app-theme .mat-fab.mat-accent.mat-button-disabled, .app-theme .mat-fab.mat-warn.mat-button-disabled, .app-theme .mat-fab.mat-button-disabled.mat-button-disabled, .app-theme .mat-mini-fab.mat-primary.mat-button-disabled, .app-theme .mat-mini-fab.mat-accent.mat-button-disabled, .app-theme .mat-mini-fab.mat-warn.mat-button-disabled, .app-theme .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(0, 0, 0, 0.12);
}
.app-theme .mat-flat-button.mat-primary .mat-ripple-element, .app-theme .mat-raised-button.mat-primary .mat-ripple-element, .app-theme .mat-fab.mat-primary .mat-ripple-element, .app-theme .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app-theme .mat-flat-button.mat-accent .mat-ripple-element, .app-theme .mat-raised-button.mat-accent .mat-ripple-element, .app-theme .mat-fab.mat-accent .mat-ripple-element, .app-theme .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app-theme .mat-flat-button.mat-warn .mat-ripple-element, .app-theme .mat-raised-button.mat-warn .mat-ripple-element, .app-theme .mat-fab.mat-warn .mat-ripple-element, .app-theme .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app-theme .mat-stroked-button:not([class*=mat-elevation-z]), .app-theme .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app-theme .mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app-theme .mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.app-theme .mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app-theme .mat-fab:not([class*=mat-elevation-z]), .app-theme .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.app-theme .mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .app-theme .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.app-theme .mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .app-theme .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app-theme .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.app-theme .mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app-theme .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.app-theme .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
.app-theme .mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.app-theme .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}
.app-theme .mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
.app-theme .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}
.app-theme .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #e0e0e0;
}
.app-theme [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #e0e0e0;
}
.app-theme .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #e0e0e0;
}
.app-theme .mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
}
.app-theme .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}
.app-theme .mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee;
}
.app-theme .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.app-theme .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}
.app-theme .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.app-theme .mat-button-toggle-group-appearance-standard {
  border: solid 1px #e0e0e0;
}
.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 48px;
}

.app-theme .mat-card {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.app-theme .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.app-theme .mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app-theme .mat-card-subtitle {
  color: rgba(0, 0, 0, 0.54);
}
.app-theme .mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.54);
}
.app-theme .mat-checkbox-checkmark {
  fill: #fafafa;
}
.app-theme .mat-checkbox-checkmark-path {
  stroke: #fafafa !important;
}
.app-theme .mat-checkbox-mixedmark {
  background-color: #fafafa;
}
.app-theme .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .app-theme .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: black;
}
.app-theme .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .app-theme .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #e927c1;
}
.app-theme .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .app-theme .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #f44336;
}
.app-theme .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .app-theme .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.app-theme .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.app-theme .mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.38);
}
.app-theme .mat-checkbox .mat-ripple-element {
  background-color: black;
}
.app-theme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.app-theme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: black;
}
.app-theme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.app-theme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #e927c1;
}
.app-theme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.app-theme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #f44336;
}
.app-theme .mat-chip.mat-standard-chip {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.app-theme .mat-chip.mat-standard-chip .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.app-theme .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.app-theme .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.app-theme .mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.app-theme .mat-chip.mat-standard-chip::after {
  background: black;
}
.app-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: black;
  color: white;
}
.app-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.app-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #f44336;
  color: white;
}
.app-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.app-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #e927c1;
  color: white;
}
.app-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.app-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app-theme .mat-table {
  background: white;
}
.app-theme .mat-table thead, .app-theme .mat-table tbody, .app-theme .mat-table tfoot,
.app-theme mat-header-row, .app-theme mat-row, .app-theme mat-footer-row,
.app-theme [mat-header-row], .app-theme [mat-row], .app-theme [mat-footer-row],
.app-theme .mat-table-sticky {
  background: inherit;
}
.app-theme mat-row, .app-theme mat-header-row, .app-theme mat-footer-row,
.app-theme th.mat-header-cell, .app-theme td.mat-cell, .app-theme td.mat-footer-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
.app-theme .mat-header-cell {
  color: rgba(0, 0, 0, 0.54);
}
.app-theme .mat-cell, .app-theme .mat-footer-cell {
  color: rgba(0, 0, 0, 0.87);
}
.app-theme .mat-calendar-arrow {
  fill: rgba(0, 0, 0, 0.54);
}
.app-theme .mat-datepicker-toggle,
.app-theme .mat-datepicker-content .mat-calendar-next-button,
.app-theme .mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}
.app-theme .mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}
.app-theme .mat-calendar-table-header,
.app-theme .mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}
.app-theme .mat-calendar-body-cell-content,
.app-theme .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}
.app-theme .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}
.app-theme .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}
.app-theme .mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}
.app-theme .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}
.app-theme .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}
.app-theme .mat-calendar-body-in-range::before {
  background: rgba(0, 0, 0, 0.2);
}
.app-theme .mat-calendar-body-comparison-identical,
.app-theme .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.app-theme .mat-calendar-body-comparison-bridge-start::before,
.app-theme [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(0, 0, 0, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app-theme .mat-calendar-body-comparison-bridge-end::before,
.app-theme [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(0, 0, 0, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app-theme .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.app-theme .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.app-theme .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.app-theme .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.app-theme .mat-calendar-body-selected {
  background-color: black;
  color: white;
}
.app-theme .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(0, 0, 0, 0.4);
}
.app-theme .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.app-theme .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.app-theme .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(0, 0, 0, 0.3);
}
@media (hover: hover) {
  .app-theme .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(0, 0, 0, 0.3);
  }
}
.app-theme .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.app-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(233, 39, 193, 0.2);
}
.app-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.app-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.app-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.app-theme .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(233, 39, 193, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.app-theme .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(233, 39, 193, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.app-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.app-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.app-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.app-theme .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #e927c1;
  color: white;
}
.app-theme .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(233, 39, 193, 0.4);
}
.app-theme .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.app-theme .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.app-theme .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(233, 39, 193, 0.3);
}
@media (hover: hover) {
  .app-theme .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(233, 39, 193, 0.3);
  }
}
.app-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(244, 67, 54, 0.2);
}
.app-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.app-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.app-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.app-theme .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.app-theme .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.app-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.app-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.app-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.app-theme .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #f44336;
  color: white;
}
.app-theme .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(244, 67, 54, 0.4);
}
.app-theme .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.app-theme .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.app-theme .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(244, 67, 54, 0.3);
}
@media (hover: hover) {
  .app-theme .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(244, 67, 54, 0.3);
  }
}
.app-theme .mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.app-theme .mat-datepicker-toggle-active {
  color: black;
}
.app-theme .mat-datepicker-toggle-active.mat-accent {
  color: #e927c1;
}
.app-theme .mat-datepicker-toggle-active.mat-warn {
  color: #f44336;
}
.app-theme .mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}
.app-theme .mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.app-theme .mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.app-theme .mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}
.app-theme .mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.app-theme .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app-theme .mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.app-theme .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .app-theme .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .app-theme .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}
@media (hover: none) {
  .app-theme .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.app-theme .mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}
.app-theme .mat-expansion-panel-header-description,
.app-theme .mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}
.app-theme .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.app-theme .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.app-theme .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.mat-expansion-panel-header {
  height: 48px;
}
.mat-expansion-panel-header.mat-expanded {
  height: 64px;
}

.app-theme .mat-form-field-label {
  color: rgba(0, 0, 0, 0.6);
}
.app-theme .mat-hint {
  color: rgba(0, 0, 0, 0.6);
}
.app-theme .mat-form-field.mat-focused .mat-form-field-label {
  color: black;
}
.app-theme .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #e927c1;
}
.app-theme .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #f44336;
}
.app-theme .mat-focused .mat-form-field-required-marker {
  color: #e927c1;
}
.app-theme .mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87);
}
.app-theme .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: black;
}
.app-theme .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #e927c1;
}
.app-theme .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #f44336;
}
.app-theme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: black;
}
.app-theme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #e927c1;
}
.app-theme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #f44336;
}
.app-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #f44336;
}
.app-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.app-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #f44336;
}
.app-theme .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.app-theme .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #f44336;
}
.app-theme .mat-error {
  color: #f44336;
}
.app-theme .mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(0, 0, 0, 0.54);
}
.app-theme .mat-form-field-appearance-legacy .mat-hint {
  color: rgba(0, 0, 0, 0.54);
}
.app-theme .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.app-theme .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.app-theme .mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.app-theme .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.app-theme .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.04);
}
.app-theme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.02);
}
.app-theme .mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.42);
}
.app-theme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.app-theme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}
.app-theme .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}
.app-theme .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87);
}
.app-theme .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: black;
}
.app-theme .mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #e927c1;
}
.app-theme .mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #f44336;
}
.app-theme .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #f44336;
}
.app-theme .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.app-theme .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06);
}
.app-theme .mat-icon.mat-primary {
  color: black;
}
.app-theme .mat-icon.mat-accent {
  color: #e927c1;
}
.app-theme .mat-icon.mat-warn {
  color: #f44336;
}
.app-theme .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}
.app-theme .mat-input-element:disabled,
.app-theme .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}
.app-theme .mat-input-element {
  caret-color: black;
}
.app-theme .mat-input-element::placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.app-theme .mat-input-element::-moz-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.app-theme .mat-input-element::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.app-theme .mat-input-element:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.app-theme .mat-form-field.mat-accent .mat-input-element {
  caret-color: #e927c1;
}
.app-theme .mat-form-field.mat-warn .mat-input-element,
.app-theme .mat-form-field-invalid .mat-input-element {
  caret-color: #f44336;
}
.app-theme .mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #f44336;
}
.app-theme .mat-list-base .mat-list-item {
  color: rgba(0, 0, 0, 0.87);
}
.app-theme .mat-list-base .mat-list-option {
  color: rgba(0, 0, 0, 0.87);
}
.app-theme .mat-list-base .mat-subheader {
  color: rgba(0, 0, 0, 0.54);
}
.app-theme .mat-list-base .mat-list-item-disabled {
  background-color: #eeeeee;
  color: rgba(0, 0, 0, 0.38);
}
.app-theme .mat-list-option:hover, .app-theme .mat-list-option:focus,
.app-theme .mat-nav-list .mat-list-item:hover,
.app-theme .mat-nav-list .mat-list-item:focus,
.app-theme .mat-action-list .mat-list-item:hover,
.app-theme .mat-action-list .mat-list-item:focus {
  background: rgba(0, 0, 0, 0.04);
}
.app-theme .mat-list-single-selected-option, .app-theme .mat-list-single-selected-option:hover, .app-theme .mat-list-single-selected-option:focus {
  background: rgba(0, 0, 0, 0.12);
}
.app-theme .mat-menu-panel {
  background: white;
}
.app-theme .mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app-theme .mat-menu-item {
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
}
.app-theme .mat-menu-item[disabled],
.app-theme .mat-menu-item[disabled] .mat-menu-submenu-icon,
.app-theme .mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(0, 0, 0, 0.38);
}
.app-theme .mat-menu-item .mat-icon-no-color,
.app-theme .mat-menu-submenu-icon {
  color: rgba(0, 0, 0, 0.54);
}
.app-theme .mat-menu-item:hover:not([disabled]),
.app-theme .mat-menu-item.cdk-program-focused:not([disabled]),
.app-theme .mat-menu-item.cdk-keyboard-focused:not([disabled]),
.app-theme .mat-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}
.app-theme .mat-paginator {
  background: white;
}
.app-theme .mat-paginator,
.app-theme .mat-paginator-page-size .mat-select-trigger {
  color: rgba(0, 0, 0, 0.54);
}
.app-theme .mat-paginator-decrement,
.app-theme .mat-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}
.app-theme .mat-paginator-first,
.app-theme .mat-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}
.app-theme .mat-icon-button[disabled] .mat-paginator-decrement,
.app-theme .mat-icon-button[disabled] .mat-paginator-increment,
.app-theme .mat-icon-button[disabled] .mat-paginator-first,
.app-theme .mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, 0.38);
}
.mat-paginator-container {
  min-height: 56px;
}

.app-theme .mat-progress-bar-background {
  fill: #bcbcbc;
}
.app-theme .mat-progress-bar-buffer {
  background-color: #bcbcbc;
}
.app-theme .mat-progress-bar-fill::after {
  background-color: black;
}
.app-theme .mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #f6c5ec;
}
.app-theme .mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #f6c5ec;
}
.app-theme .mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #e927c1;
}
.app-theme .mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #f9ccc9;
}
.app-theme .mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #f9ccc9;
}
.app-theme .mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #f44336;
}
.app-theme .mat-progress-spinner circle, .app-theme .mat-spinner circle {
  stroke: black;
}
.app-theme .mat-progress-spinner.mat-accent circle, .app-theme .mat-spinner.mat-accent circle {
  stroke: #e927c1;
}
.app-theme .mat-progress-spinner.mat-warn circle, .app-theme .mat-spinner.mat-warn circle {
  stroke: #f44336;
}
.app-theme .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.54);
}
.app-theme .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: black;
}
.app-theme .mat-radio-button.mat-primary .mat-radio-inner-circle,
.app-theme .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .app-theme .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .app-theme .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: black;
}
.app-theme .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #e927c1;
}
.app-theme .mat-radio-button.mat-accent .mat-radio-inner-circle,
.app-theme .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .app-theme .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .app-theme .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #e927c1;
}
.app-theme .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #f44336;
}
.app-theme .mat-radio-button.mat-warn .mat-radio-inner-circle,
.app-theme .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .app-theme .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .app-theme .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #f44336;
}
.app-theme .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.app-theme .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}
.app-theme .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.app-theme .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38);
}
.app-theme .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38);
}
.app-theme .mat-radio-button .mat-ripple-element {
  background-color: black;
}
.app-theme .mat-select-value {
  color: rgba(0, 0, 0, 0.87);
}
.app-theme .mat-select-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.app-theme .mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38);
}
.app-theme .mat-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}
.app-theme .mat-select-panel {
  background: white;
}
.app-theme .mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app-theme .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.12);
}
.app-theme .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: black;
}
.app-theme .mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #e927c1;
}
.app-theme .mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #f44336;
}
.app-theme .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #f44336;
}
.app-theme .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}
.app-theme .mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.app-theme .mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.app-theme .mat-drawer.mat-drawer-push {
  background-color: white;
}
.app-theme .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.app-theme .mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.app-theme .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.app-theme [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.app-theme [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.app-theme .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}
.app-theme .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #e927c1;
}
.app-theme .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(233, 39, 193, 0.54);
}
.app-theme .mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #e927c1;
}
.app-theme .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: black;
}
.app-theme .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.54);
}
.app-theme .mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: black;
}
.app-theme .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #f44336;
}
.app-theme .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(244, 67, 54, 0.54);
}
.app-theme .mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #f44336;
}
.app-theme .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: black;
}
.app-theme .mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #fafafa;
}
.app-theme .mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38);
}
.app-theme .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.app-theme .mat-slider.mat-primary .mat-slider-track-fill,
.app-theme .mat-slider.mat-primary .mat-slider-thumb,
.app-theme .mat-slider.mat-primary .mat-slider-thumb-label {
  background-color: black;
}
.app-theme .mat-slider.mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.app-theme .mat-slider.mat-primary .mat-slider-focus-ring {
  background-color: rgba(0, 0, 0, 0.2);
}
.app-theme .mat-slider.mat-accent .mat-slider-track-fill,
.app-theme .mat-slider.mat-accent .mat-slider-thumb,
.app-theme .mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: #e927c1;
}
.app-theme .mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: white;
}
.app-theme .mat-slider.mat-accent .mat-slider-focus-ring {
  background-color: rgba(233, 39, 193, 0.2);
}
.app-theme .mat-slider.mat-warn .mat-slider-track-fill,
.app-theme .mat-slider.mat-warn .mat-slider-thumb,
.app-theme .mat-slider.mat-warn .mat-slider-thumb-label {
  background-color: #f44336;
}
.app-theme .mat-slider.mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.app-theme .mat-slider.mat-warn .mat-slider-focus-ring {
  background-color: rgba(244, 67, 54, 0.2);
}
.app-theme .mat-slider:hover .mat-slider-track-background,
.app-theme .mat-slider.cdk-focused .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.38);
}
.app-theme .mat-slider.mat-slider-disabled .mat-slider-track-background,
.app-theme .mat-slider.mat-slider-disabled .mat-slider-track-fill,
.app-theme .mat-slider.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(0, 0, 0, 0.26);
}
.app-theme .mat-slider.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.app-theme .mat-slider.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(0, 0, 0, 0.12);
}
.app-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.app-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.87);
}
.app-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.app-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.26);
}
.app-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
}
.app-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .app-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.38);
}
.app-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .app-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
}
.app-theme .mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(0, 0, 0, 0.7);
}
.app-theme .mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.app-theme .mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.app-theme .mat-step-header.cdk-keyboard-focused, .app-theme .mat-step-header.cdk-program-focused, .app-theme .mat-step-header:hover:not([aria-disabled]), .app-theme .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(0, 0, 0, 0.04);
}
.app-theme .mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .app-theme .mat-step-header:hover {
    background: none;
  }
}
.app-theme .mat-step-header .mat-step-label,
.app-theme .mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.app-theme .mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: white;
}
.app-theme .mat-step-header .mat-step-icon-selected,
.app-theme .mat-step-header .mat-step-icon-state-done,
.app-theme .mat-step-header .mat-step-icon-state-edit {
  background-color: black;
  color: white;
}
.app-theme .mat-step-header.mat-accent .mat-step-icon {
  color: white;
}
.app-theme .mat-step-header.mat-accent .mat-step-icon-selected,
.app-theme .mat-step-header.mat-accent .mat-step-icon-state-done,
.app-theme .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #e927c1;
  color: white;
}
.app-theme .mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.app-theme .mat-step-header.mat-warn .mat-step-icon-selected,
.app-theme .mat-step-header.mat-warn .mat-step-icon-state-done,
.app-theme .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #f44336;
  color: white;
}
.app-theme .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #f44336;
}
.app-theme .mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}
.app-theme .mat-step-header .mat-step-label.mat-step-label-error {
  color: #f44336;
}
.app-theme .mat-stepper-horizontal, .app-theme .mat-stepper-vertical {
  background-color: white;
}
.app-theme .mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}
.app-theme .mat-horizontal-stepper-header::before,
.app-theme .mat-horizontal-stepper-header::after,
.app-theme .mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.mat-horizontal-stepper-header {
  height: 72px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header,
.mat-vertical-stepper-header {
  padding: 24px 24px;
}

.mat-stepper-vertical-line::before {
  top: -16px;
  bottom: -16px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header::after, .mat-stepper-label-position-bottom .mat-horizontal-stepper-header::before {
  top: 36px;
}

.mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
  top: 36px;
}

.app-theme .mat-sort-header-arrow {
  color: #757575;
}
.app-theme .mat-tab-nav-bar,
.app-theme .mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.app-theme .mat-tab-group-inverted-header .mat-tab-nav-bar,
.app-theme .mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
}
.app-theme .mat-tab-label, .app-theme .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.app-theme .mat-tab-label.mat-tab-disabled, .app-theme .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.app-theme .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87);
}
.app-theme .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.38);
}
.app-theme .mat-tab-group[class*=mat-background-] > .mat-tab-header,
.app-theme .mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}
.app-theme .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app-theme .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app-theme .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app-theme .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app-theme .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app-theme .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app-theme .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app-theme .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(77, 77, 77, 0.3);
}
.app-theme .mat-tab-group.mat-primary .mat-ink-bar, .app-theme .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: black;
}
.app-theme .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .app-theme .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .app-theme .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .app-theme .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.app-theme .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app-theme .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app-theme .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app-theme .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app-theme .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app-theme .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app-theme .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app-theme .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(240, 104, 212, 0.3);
}
.app-theme .mat-tab-group.mat-accent .mat-ink-bar, .app-theme .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #e927c1;
}
.app-theme .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .app-theme .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .app-theme .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .app-theme .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.app-theme .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app-theme .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app-theme .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app-theme .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app-theme .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app-theme .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app-theme .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app-theme .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(229, 115, 115, 0.3);
}
.app-theme .mat-tab-group.mat-warn .mat-ink-bar, .app-theme .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #f44336;
}
.app-theme .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .app-theme .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .app-theme .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .app-theme .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.app-theme .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app-theme .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app-theme .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app-theme .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(77, 77, 77, 0.3);
}
.app-theme .mat-tab-group.mat-background-primary > .mat-tab-header, .app-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container, .app-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .app-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .app-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .app-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: black;
}
.app-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .app-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .app-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .app-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.app-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .app-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .app-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .app-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.app-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.app-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.app-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.app-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .app-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.app-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.app-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.app-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.app-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.app-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .app-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.app-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.app-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.app-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.app-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .app-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.app-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.app-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.app-theme .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app-theme .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app-theme .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app-theme .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(240, 104, 212, 0.3);
}
.app-theme .mat-tab-group.mat-background-accent > .mat-tab-header, .app-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container, .app-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .app-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .app-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .app-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #e927c1;
}
.app-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .app-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .app-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .app-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.app-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .app-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .app-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .app-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.app-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.app-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.app-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.app-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .app-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.app-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.app-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.app-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.app-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.app-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .app-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.app-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.app-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.app-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.app-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .app-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.app-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.app-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.app-theme .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app-theme .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app-theme .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app-theme .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(229, 115, 115, 0.3);
}
.app-theme .mat-tab-group.mat-background-warn > .mat-tab-header, .app-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container, .app-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .app-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .app-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .app-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #f44336;
}
.app-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .app-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .app-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .app-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.app-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .app-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .app-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .app-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.app-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.app-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.app-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.app-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .app-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.app-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.app-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.app-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.app-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.app-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .app-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.app-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.app-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.app-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.app-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .app-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.app-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.app-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.app-theme .mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87);
}
.app-theme .mat-toolbar.mat-primary {
  background: black;
  color: white;
}
.app-theme .mat-toolbar.mat-accent {
  background: #e927c1;
  color: white;
}
.app-theme .mat-toolbar.mat-warn {
  background: #f44336;
  color: white;
}
.app-theme .mat-toolbar .mat-form-field-underline,
.app-theme .mat-toolbar .mat-form-field-ripple,
.app-theme .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.app-theme .mat-toolbar .mat-form-field-label,
.app-theme .mat-toolbar .mat-focused .mat-form-field-label,
.app-theme .mat-toolbar .mat-select-value,
.app-theme .mat-toolbar .mat-select-arrow,
.app-theme .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.app-theme .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.mat-toolbar-multiple-rows {
  min-height: 64px;
}

.mat-toolbar-row, .mat-toolbar-single-row {
  height: 64px;
}

@media (max-width: 599px) {
  .mat-toolbar-multiple-rows {
    min-height: 56px;
  }

  .mat-toolbar-row, .mat-toolbar-single-row {
    height: 56px;
  }
}
.app-theme .mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}
.app-theme .mat-tree {
  background: white;
}
.app-theme .mat-tree-node,
.app-theme .mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}
.mat-tree-node {
  min-height: 48px;
}

.app-theme .mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.app-theme .mat-simple-snackbar-action {
  color: #e927c1;
}

.mat-badge-content {
  font-weight: 600;
  font-size: 12px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-badge-small .mat-badge-content {
  font-size: 9px;
}

.mat-badge-large .mat-badge-content {
  font-size: 24px;
}

.mat-h1,
.mat-headline,
.mat-typography .mat-h1,
.mat-typography .mat-headline,
.mat-typography h1 {
  font: 400 24px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h2,
.mat-title,
.mat-typography .mat-h2,
.mat-typography .mat-title,
.mat-typography h2 {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h3,
.mat-subheading-2,
.mat-typography .mat-h3,
.mat-typography .mat-subheading-2,
.mat-typography h3 {
  font: 400 16px / 28px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h4,
.mat-subheading-1,
.mat-typography .mat-h4,
.mat-typography .mat-subheading-1,
.mat-typography h4 {
  font: 400 15px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h5,
.mat-typography .mat-h5,
.mat-typography h5 {
  font: 400 calc(14px * 0.83) / 18px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}

.mat-h6,
.mat-typography .mat-h6,
.mat-typography h6 {
  font: 400 calc(14px * 0.67) / 18px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}

.mat-body-strong,
.mat-body-2,
.mat-typography .mat-body-strong,
.mat-typography .mat-body-2 {
  font: 500 14px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-body,
.mat-body-1,
.mat-typography .mat-body,
.mat-typography .mat-body-1,
.mat-typography {
  font: 400 14px / 18px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.mat-body p,
.mat-body-1 p,
.mat-typography .mat-body p,
.mat-typography .mat-body-1 p,
.mat-typography p {
  margin: 0 0 12px;
}

.mat-small,
.mat-caption,
.mat-typography .mat-small,
.mat-typography .mat-caption {
  font: 400 12px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-display-4,
.mat-typography .mat-display-4 {
  font: 300 112px / 112px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 56px;
}

.mat-display-3,
.mat-typography .mat-display-3 {
  font: 400 56px / 56px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 64px;
}

.mat-display-2,
.mat-typography .mat-display-2 {
  font: 400 45px / 48px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 64px;
}

.mat-display-1,
.mat-typography .mat-display-1 {
  font: 400 34px / 40px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 64px;
}

.mat-bottom-sheet-container {
  font: 400 14px / 18px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-button, .mat-raised-button, .mat-icon-button, .mat-stroked-button,
.mat-flat-button, .mat-fab, .mat-mini-fab {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-button-toggle {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-card {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-card-title {
  font-size: 24px;
  font-weight: 500;
}

.mat-card-header .mat-card-title {
  font-size: 20px;
}

.mat-card-subtitle,
.mat-card-content {
  font-size: 14px;
}

.mat-checkbox {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-checkbox-layout .mat-checkbox-label {
  line-height: 24px;
}

.mat-chip {
  font-size: 14px;
  font-weight: 500;
}
.mat-chip .mat-chip-trailing-icon.mat-icon,
.mat-chip .mat-chip-remove.mat-icon {
  font-size: 18px;
}

.mat-table {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-header-cell {
  font-size: 12px;
  font-weight: 500;
}

.mat-cell, .mat-footer-cell {
  font-size: 14px;
}

.mat-calendar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-calendar-body {
  font-size: 13px;
}

.mat-calendar-body-label,
.mat-calendar-period-button {
  font-size: 14px;
  font-weight: 500;
}

.mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400;
}

.mat-dialog-title {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-expansion-panel-header {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 15px;
  font-weight: 400;
}

.mat-expansion-panel-content {
  font: 400 14px / 18px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-form-field {
  font: 400 16px / 1.125 Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-form-field-wrapper {
  padding-bottom: 1.34375em;
}

.mat-form-field-prefix .mat-icon,
.mat-form-field-suffix .mat-icon {
  font-size: 150%;
  line-height: 1.125;
}
.mat-form-field-prefix .mat-icon-button,
.mat-form-field-suffix .mat-icon-button {
  height: 1.5em;
  width: 1.5em;
}
.mat-form-field-prefix .mat-icon-button .mat-icon,
.mat-form-field-suffix .mat-icon-button .mat-icon {
  height: 1.125em;
  line-height: 1.125;
}

.mat-form-field-infix {
  padding: 0.5em 0;
  border-top: 0.84375em solid transparent;
}

.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34373em) scale(0.75);
  width: 133.3333533333%;
}
.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34372em) scale(0.75);
  width: 133.3333633333%;
}

.mat-form-field-label-wrapper {
  top: -0.84375em;
  padding-top: 0.84375em;
}

.mat-form-field-label {
  top: 1.34375em;
}

.mat-form-field-underline {
  bottom: 1.34375em;
}

.mat-form-field-subscript-wrapper {
  font-size: 75%;
  margin-top: 0.6666666667em;
  top: calc(100% - 1.7916666667em);
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.4375em 0;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00106px);
  width: 133.3333933333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00107px);
  width: 133.3334033333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00108px);
  width: 133.3334133333%;
}
.mat-form-field-appearance-legacy .mat-form-field-label {
  top: 1.28125em;
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: 0.5416666667em;
  top: calc(100% - 1.6666666667em);
}

@media print {
  .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28116em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28115em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28114em) scale(0.75);
  }
}
.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.25em 0 0.75em 0;
}
.mat-form-field-appearance-fill .mat-form-field-label {
  top: 1.09375em;
  margin-top: -0.5em;
}
.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59373em) scale(0.75);
  width: 133.3333533333%;
}
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59372em) scale(0.75);
  width: 133.3333633333%;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1em 0 1em 0;
}
.mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.84375em;
  margin-top: -0.25em;
}
.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59373em) scale(0.75);
  width: 133.3333533333%;
}
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59372em) scale(0.75);
  width: 133.3333633333%;
}

.mat-grid-tile-header,
.mat-grid-tile-footer {
  font-size: 14px;
}
.mat-grid-tile-header .mat-line,
.mat-grid-tile-footer .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-grid-tile-header .mat-line:nth-child(n+2),
.mat-grid-tile-footer .mat-line:nth-child(n+2) {
  font-size: 12px;
}

input.mat-input-element {
  margin-top: -0.0625em;
}

.mat-menu-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
}

.mat-radio-button {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-select {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-select-trigger {
  height: 1.125em;
}

.mat-slide-toggle-content {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-slider-thumb-label-text {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}

.mat-stepper-vertical, .mat-stepper-horizontal {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-step-label {
  font-size: 14px;
  font-weight: 400;
}

.mat-step-sub-label-error {
  font-weight: normal;
}

.mat-step-label-error {
  font-size: 14px;
}

.mat-step-label-selected {
  font-size: 14px;
  font-weight: 500;
}

.mat-tab-group {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-tab-label, .mat-tab-link {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-toolbar,
.mat-toolbar h1,
.mat-toolbar h2,
.mat-toolbar h3,
.mat-toolbar h4,
.mat-toolbar h5,
.mat-toolbar h6 {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0;
}

.mat-tooltip {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.mat-tooltip-handset {
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.mat-list-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-list-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-list-base .mat-list-item {
  font-size: 16px;
}
.mat-list-base .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-list-option {
  font-size: 16px;
}
.mat-list-base .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-list-base[dense] .mat-list-item {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}

.mat-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 16px;
}

.mat-optgroup-label {
  font: 500 14px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-simple-snackbar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
}

.mat-simple-snackbar-action {
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
}

.mat-tree {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-tree-node,
.mat-nested-tree-node {
  font-weight: 400;
  font-size: 14px;
}

.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-backdrop-noop-animation {
  transition: none;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start {
  /*!*/
}
@keyframes cdk-text-field-autofill-end {
  /*!*/
}
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}

.mat-mdc-focus-indicator {
  position: relative;
}

.mat-display-1,
.mat-display-2,
.mat-display-3,
.mat-display-4 {
  color: #e927c1;
}

.mat-caption {
  color: black;
}

.dialog {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.dialog mat-dialog-content {
  flex-grow: 1;
}

.no-padding-dialog .mat-dialog-container {
  padding: 0px !important;
}

.no-padding-form-field .mat-form-field-wrapper {
  padding: 0px !important;
  padding-bottom: 0px !important;
}

.mat-paginator-range-label {
  margin-left: 10px !important;
  margin-right: 10px !important;
}

mat-sidenav .sidenav-sm,
mat-sidenav .sidenav-md,
mat-sidenav .sidenav-lg {
  width: 100vw;
}
@media (min-width: 576px) {
  mat-sidenav .sidenav-sm,
mat-sidenav .sidenav-md,
mat-sidenav .sidenav-lg {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}
mat-sidenav .sidenav-sm mat-toolbar,
mat-sidenav .sidenav-sm mat-toolbar,
mat-sidenav .sidenav-md mat-toolbar,
mat-sidenav .sidenav-md mat-toolbar,
mat-sidenav .sidenav-lg mat-toolbar,
mat-sidenav .sidenav-lg mat-toolbar {
  left: auto !important;
  right: auto !important;
  width: 100vw !important;
}
@media (min-width: 576px) {
  mat-sidenav .sidenav-sm {
    width: 100vw;
  }
}
@media (min-width: 576px) {
  mat-sidenav .sidenav-sm mat-toolbar,
mat-sidenav .sidenav-sm mat-toolbar {
    width: 100vw !important;
  }
}
@media (min-width: 576px) {
  mat-sidenav .sidenav-md {
    width: 100vw;
  }
}
@media (min-width: 576px) {
  mat-sidenav .sidenav-md mat-toolbar,
mat-sidenav .sidenav-md mat-toolbar {
    width: 100vw !important;
  }
}
@media (min-width: 576px) {
  mat-sidenav .sidenav-lg {
    width: 100vw;
  }
}
@media (min-width: 576px) {
  mat-sidenav .sidenav-lg mat-toolbar,
mat-sidenav .sidenav-lg mat-toolbar {
    width: 100vw !important;
  }
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

.container,
.container-fluid,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 600px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 960px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 1280px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1920px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xl,
.col-xl-auto, .col-xl-24, .col-xl-23, .col-xl-22, .col-xl-21, .col-xl-20, .col-xl-19, .col-xl-18, .col-xl-17, .col-xl-16, .col-xl-15, .col-xl-14, .col-xl-13, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-24, .col-lg-23, .col-lg-22, .col-lg-21, .col-lg-20, .col-lg-19, .col-lg-18, .col-lg-17, .col-lg-16, .col-lg-15, .col-lg-14, .col-lg-13, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-24, .col-md-23, .col-md-22, .col-md-21, .col-md-20, .col-md-19, .col-md-18, .col-md-17, .col-md-16, .col-md-15, .col-md-14, .col-md-13, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-24, .col-sm-23, .col-sm-22, .col-sm-21, .col-sm-20, .col-sm-19, .col-sm-18, .col-sm-17, .col-sm-16, .col-sm-15, .col-sm-14, .col-sm-13, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col,
.col-auto, .col-24, .col-23, .col-22, .col-21, .col-20, .col-19, .col-18, .col-17, .col-16, .col-15, .col-14, .col-13, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 4.16666667%;
  max-width: 4.16666667%;
}

.col-2 {
  flex: 0 0 8.33333333%;
  max-width: 8.33333333%;
}

.col-3 {
  flex: 0 0 12.5%;
  max-width: 12.5%;
}

.col-4 {
  flex: 0 0 16.66666667%;
  max-width: 16.66666667%;
}

.col-5 {
  flex: 0 0 20.83333333%;
  max-width: 20.83333333%;
}

.col-6 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-7 {
  flex: 0 0 29.16666667%;
  max-width: 29.16666667%;
}

.col-8 {
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%;
}

.col-9 {
  flex: 0 0 37.5%;
  max-width: 37.5%;
}

.col-10 {
  flex: 0 0 41.66666667%;
  max-width: 41.66666667%;
}

.col-11 {
  flex: 0 0 45.83333333%;
  max-width: 45.83333333%;
}

.col-12 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-13 {
  flex: 0 0 54.16666667%;
  max-width: 54.16666667%;
}

.col-14 {
  flex: 0 0 58.33333333%;
  max-width: 58.33333333%;
}

.col-15 {
  flex: 0 0 62.5%;
  max-width: 62.5%;
}

.col-16 {
  flex: 0 0 66.66666667%;
  max-width: 66.66666667%;
}

.col-17 {
  flex: 0 0 70.83333333%;
  max-width: 70.83333333%;
}

.col-18 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-19 {
  flex: 0 0 79.16666667%;
  max-width: 79.16666667%;
}

.col-20 {
  flex: 0 0 83.33333333%;
  max-width: 83.33333333%;
}

.col-21 {
  flex: 0 0 87.5%;
  max-width: 87.5%;
}

.col-22 {
  flex: 0 0 91.66666667%;
  max-width: 91.66666667%;
}

.col-23 {
  flex: 0 0 95.83333333%;
  max-width: 95.83333333%;
}

.col-24 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 25;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.order-13 {
  order: 13;
}

.order-14 {
  order: 14;
}

.order-15 {
  order: 15;
}

.order-16 {
  order: 16;
}

.order-17 {
  order: 17;
}

.order-18 {
  order: 18;
}

.order-19 {
  order: 19;
}

.order-20 {
  order: 20;
}

.order-21 {
  order: 21;
}

.order-22 {
  order: 22;
}

.order-23 {
  order: 23;
}

.order-24 {
  order: 24;
}

.offset-1 {
  margin-left: 4.16666667%;
}

.offset-2 {
  margin-left: 8.33333333%;
}

.offset-3 {
  margin-left: 12.5%;
}

.offset-4 {
  margin-left: 16.66666667%;
}

.offset-5 {
  margin-left: 20.83333333%;
}

.offset-6 {
  margin-left: 25%;
}

.offset-7 {
  margin-left: 29.16666667%;
}

.offset-8 {
  margin-left: 33.33333333%;
}

.offset-9 {
  margin-left: 37.5%;
}

.offset-10 {
  margin-left: 41.66666667%;
}

.offset-11 {
  margin-left: 45.83333333%;
}

.offset-12 {
  margin-left: 50%;
}

.offset-13 {
  margin-left: 54.16666667%;
}

.offset-14 {
  margin-left: 58.33333333%;
}

.offset-15 {
  margin-left: 62.5%;
}

.offset-16 {
  margin-left: 66.66666667%;
}

.offset-17 {
  margin-left: 70.83333333%;
}

.offset-18 {
  margin-left: 75%;
}

.offset-19 {
  margin-left: 79.16666667%;
}

.offset-20 {
  margin-left: 83.33333333%;
}

.offset-21 {
  margin-left: 87.5%;
}

.offset-22 {
  margin-left: 91.66666667%;
}

.offset-23 {
  margin-left: 95.83333333%;
}

@media (min-width: 600px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-sm-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-sm-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-sm-1 {
    flex: 0 0 4.16666667%;
    max-width: 4.16666667%;
  }

  .col-sm-2 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }

  .col-sm-3 {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }

  .col-sm-4 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-sm-5 {
    flex: 0 0 20.83333333%;
    max-width: 20.83333333%;
  }

  .col-sm-6 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-7 {
    flex: 0 0 29.16666667%;
    max-width: 29.16666667%;
  }

  .col-sm-8 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-sm-9 {
    flex: 0 0 37.5%;
    max-width: 37.5%;
  }

  .col-sm-10 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-sm-11 {
    flex: 0 0 45.83333333%;
    max-width: 45.83333333%;
  }

  .col-sm-12 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-13 {
    flex: 0 0 54.16666667%;
    max-width: 54.16666667%;
  }

  .col-sm-14 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }

  .col-sm-15 {
    flex: 0 0 62.5%;
    max-width: 62.5%;
  }

  .col-sm-16 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }

  .col-sm-17 {
    flex: 0 0 70.83333333%;
    max-width: 70.83333333%;
  }

  .col-sm-18 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-19 {
    flex: 0 0 79.16666667%;
    max-width: 79.16666667%;
  }

  .col-sm-20 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }

  .col-sm-21 {
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }

  .col-sm-22 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }

  .col-sm-23 {
    flex: 0 0 95.83333333%;
    max-width: 95.83333333%;
  }

  .col-sm-24 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-sm-first {
    order: -1;
  }

  .order-sm-last {
    order: 25;
  }

  .order-sm-0 {
    order: 0;
  }

  .order-sm-1 {
    order: 1;
  }

  .order-sm-2 {
    order: 2;
  }

  .order-sm-3 {
    order: 3;
  }

  .order-sm-4 {
    order: 4;
  }

  .order-sm-5 {
    order: 5;
  }

  .order-sm-6 {
    order: 6;
  }

  .order-sm-7 {
    order: 7;
  }

  .order-sm-8 {
    order: 8;
  }

  .order-sm-9 {
    order: 9;
  }

  .order-sm-10 {
    order: 10;
  }

  .order-sm-11 {
    order: 11;
  }

  .order-sm-12 {
    order: 12;
  }

  .order-sm-13 {
    order: 13;
  }

  .order-sm-14 {
    order: 14;
  }

  .order-sm-15 {
    order: 15;
  }

  .order-sm-16 {
    order: 16;
  }

  .order-sm-17 {
    order: 17;
  }

  .order-sm-18 {
    order: 18;
  }

  .order-sm-19 {
    order: 19;
  }

  .order-sm-20 {
    order: 20;
  }

  .order-sm-21 {
    order: 21;
  }

  .order-sm-22 {
    order: 22;
  }

  .order-sm-23 {
    order: 23;
  }

  .order-sm-24 {
    order: 24;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 4.16666667%;
  }

  .offset-sm-2 {
    margin-left: 8.33333333%;
  }

  .offset-sm-3 {
    margin-left: 12.5%;
  }

  .offset-sm-4 {
    margin-left: 16.66666667%;
  }

  .offset-sm-5 {
    margin-left: 20.83333333%;
  }

  .offset-sm-6 {
    margin-left: 25%;
  }

  .offset-sm-7 {
    margin-left: 29.16666667%;
  }

  .offset-sm-8 {
    margin-left: 33.33333333%;
  }

  .offset-sm-9 {
    margin-left: 37.5%;
  }

  .offset-sm-10 {
    margin-left: 41.66666667%;
  }

  .offset-sm-11 {
    margin-left: 45.83333333%;
  }

  .offset-sm-12 {
    margin-left: 50%;
  }

  .offset-sm-13 {
    margin-left: 54.16666667%;
  }

  .offset-sm-14 {
    margin-left: 58.33333333%;
  }

  .offset-sm-15 {
    margin-left: 62.5%;
  }

  .offset-sm-16 {
    margin-left: 66.66666667%;
  }

  .offset-sm-17 {
    margin-left: 70.83333333%;
  }

  .offset-sm-18 {
    margin-left: 75%;
  }

  .offset-sm-19 {
    margin-left: 79.16666667%;
  }

  .offset-sm-20 {
    margin-left: 83.33333333%;
  }

  .offset-sm-21 {
    margin-left: 87.5%;
  }

  .offset-sm-22 {
    margin-left: 91.66666667%;
  }

  .offset-sm-23 {
    margin-left: 95.83333333%;
  }
}
@media (min-width: 960px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-md-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-md-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-md-1 {
    flex: 0 0 4.16666667%;
    max-width: 4.16666667%;
  }

  .col-md-2 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }

  .col-md-3 {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }

  .col-md-4 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-md-5 {
    flex: 0 0 20.83333333%;
    max-width: 20.83333333%;
  }

  .col-md-6 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-7 {
    flex: 0 0 29.16666667%;
    max-width: 29.16666667%;
  }

  .col-md-8 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-md-9 {
    flex: 0 0 37.5%;
    max-width: 37.5%;
  }

  .col-md-10 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-md-11 {
    flex: 0 0 45.83333333%;
    max-width: 45.83333333%;
  }

  .col-md-12 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-13 {
    flex: 0 0 54.16666667%;
    max-width: 54.16666667%;
  }

  .col-md-14 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }

  .col-md-15 {
    flex: 0 0 62.5%;
    max-width: 62.5%;
  }

  .col-md-16 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }

  .col-md-17 {
    flex: 0 0 70.83333333%;
    max-width: 70.83333333%;
  }

  .col-md-18 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-19 {
    flex: 0 0 79.16666667%;
    max-width: 79.16666667%;
  }

  .col-md-20 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }

  .col-md-21 {
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }

  .col-md-22 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }

  .col-md-23 {
    flex: 0 0 95.83333333%;
    max-width: 95.83333333%;
  }

  .col-md-24 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-md-first {
    order: -1;
  }

  .order-md-last {
    order: 25;
  }

  .order-md-0 {
    order: 0;
  }

  .order-md-1 {
    order: 1;
  }

  .order-md-2 {
    order: 2;
  }

  .order-md-3 {
    order: 3;
  }

  .order-md-4 {
    order: 4;
  }

  .order-md-5 {
    order: 5;
  }

  .order-md-6 {
    order: 6;
  }

  .order-md-7 {
    order: 7;
  }

  .order-md-8 {
    order: 8;
  }

  .order-md-9 {
    order: 9;
  }

  .order-md-10 {
    order: 10;
  }

  .order-md-11 {
    order: 11;
  }

  .order-md-12 {
    order: 12;
  }

  .order-md-13 {
    order: 13;
  }

  .order-md-14 {
    order: 14;
  }

  .order-md-15 {
    order: 15;
  }

  .order-md-16 {
    order: 16;
  }

  .order-md-17 {
    order: 17;
  }

  .order-md-18 {
    order: 18;
  }

  .order-md-19 {
    order: 19;
  }

  .order-md-20 {
    order: 20;
  }

  .order-md-21 {
    order: 21;
  }

  .order-md-22 {
    order: 22;
  }

  .order-md-23 {
    order: 23;
  }

  .order-md-24 {
    order: 24;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 4.16666667%;
  }

  .offset-md-2 {
    margin-left: 8.33333333%;
  }

  .offset-md-3 {
    margin-left: 12.5%;
  }

  .offset-md-4 {
    margin-left: 16.66666667%;
  }

  .offset-md-5 {
    margin-left: 20.83333333%;
  }

  .offset-md-6 {
    margin-left: 25%;
  }

  .offset-md-7 {
    margin-left: 29.16666667%;
  }

  .offset-md-8 {
    margin-left: 33.33333333%;
  }

  .offset-md-9 {
    margin-left: 37.5%;
  }

  .offset-md-10 {
    margin-left: 41.66666667%;
  }

  .offset-md-11 {
    margin-left: 45.83333333%;
  }

  .offset-md-12 {
    margin-left: 50%;
  }

  .offset-md-13 {
    margin-left: 54.16666667%;
  }

  .offset-md-14 {
    margin-left: 58.33333333%;
  }

  .offset-md-15 {
    margin-left: 62.5%;
  }

  .offset-md-16 {
    margin-left: 66.66666667%;
  }

  .offset-md-17 {
    margin-left: 70.83333333%;
  }

  .offset-md-18 {
    margin-left: 75%;
  }

  .offset-md-19 {
    margin-left: 79.16666667%;
  }

  .offset-md-20 {
    margin-left: 83.33333333%;
  }

  .offset-md-21 {
    margin-left: 87.5%;
  }

  .offset-md-22 {
    margin-left: 91.66666667%;
  }

  .offset-md-23 {
    margin-left: 95.83333333%;
  }
}
@media (min-width: 1280px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-lg-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-lg-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-lg-1 {
    flex: 0 0 4.16666667%;
    max-width: 4.16666667%;
  }

  .col-lg-2 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }

  .col-lg-3 {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }

  .col-lg-4 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-lg-5 {
    flex: 0 0 20.83333333%;
    max-width: 20.83333333%;
  }

  .col-lg-6 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-7 {
    flex: 0 0 29.16666667%;
    max-width: 29.16666667%;
  }

  .col-lg-8 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-lg-9 {
    flex: 0 0 37.5%;
    max-width: 37.5%;
  }

  .col-lg-10 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-lg-11 {
    flex: 0 0 45.83333333%;
    max-width: 45.83333333%;
  }

  .col-lg-12 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-13 {
    flex: 0 0 54.16666667%;
    max-width: 54.16666667%;
  }

  .col-lg-14 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }

  .col-lg-15 {
    flex: 0 0 62.5%;
    max-width: 62.5%;
  }

  .col-lg-16 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }

  .col-lg-17 {
    flex: 0 0 70.83333333%;
    max-width: 70.83333333%;
  }

  .col-lg-18 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-19 {
    flex: 0 0 79.16666667%;
    max-width: 79.16666667%;
  }

  .col-lg-20 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }

  .col-lg-21 {
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }

  .col-lg-22 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }

  .col-lg-23 {
    flex: 0 0 95.83333333%;
    max-width: 95.83333333%;
  }

  .col-lg-24 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-lg-first {
    order: -1;
  }

  .order-lg-last {
    order: 25;
  }

  .order-lg-0 {
    order: 0;
  }

  .order-lg-1 {
    order: 1;
  }

  .order-lg-2 {
    order: 2;
  }

  .order-lg-3 {
    order: 3;
  }

  .order-lg-4 {
    order: 4;
  }

  .order-lg-5 {
    order: 5;
  }

  .order-lg-6 {
    order: 6;
  }

  .order-lg-7 {
    order: 7;
  }

  .order-lg-8 {
    order: 8;
  }

  .order-lg-9 {
    order: 9;
  }

  .order-lg-10 {
    order: 10;
  }

  .order-lg-11 {
    order: 11;
  }

  .order-lg-12 {
    order: 12;
  }

  .order-lg-13 {
    order: 13;
  }

  .order-lg-14 {
    order: 14;
  }

  .order-lg-15 {
    order: 15;
  }

  .order-lg-16 {
    order: 16;
  }

  .order-lg-17 {
    order: 17;
  }

  .order-lg-18 {
    order: 18;
  }

  .order-lg-19 {
    order: 19;
  }

  .order-lg-20 {
    order: 20;
  }

  .order-lg-21 {
    order: 21;
  }

  .order-lg-22 {
    order: 22;
  }

  .order-lg-23 {
    order: 23;
  }

  .order-lg-24 {
    order: 24;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 4.16666667%;
  }

  .offset-lg-2 {
    margin-left: 8.33333333%;
  }

  .offset-lg-3 {
    margin-left: 12.5%;
  }

  .offset-lg-4 {
    margin-left: 16.66666667%;
  }

  .offset-lg-5 {
    margin-left: 20.83333333%;
  }

  .offset-lg-6 {
    margin-left: 25%;
  }

  .offset-lg-7 {
    margin-left: 29.16666667%;
  }

  .offset-lg-8 {
    margin-left: 33.33333333%;
  }

  .offset-lg-9 {
    margin-left: 37.5%;
  }

  .offset-lg-10 {
    margin-left: 41.66666667%;
  }

  .offset-lg-11 {
    margin-left: 45.83333333%;
  }

  .offset-lg-12 {
    margin-left: 50%;
  }

  .offset-lg-13 {
    margin-left: 54.16666667%;
  }

  .offset-lg-14 {
    margin-left: 58.33333333%;
  }

  .offset-lg-15 {
    margin-left: 62.5%;
  }

  .offset-lg-16 {
    margin-left: 66.66666667%;
  }

  .offset-lg-17 {
    margin-left: 70.83333333%;
  }

  .offset-lg-18 {
    margin-left: 75%;
  }

  .offset-lg-19 {
    margin-left: 79.16666667%;
  }

  .offset-lg-20 {
    margin-left: 83.33333333%;
  }

  .offset-lg-21 {
    margin-left: 87.5%;
  }

  .offset-lg-22 {
    margin-left: 91.66666667%;
  }

  .offset-lg-23 {
    margin-left: 95.83333333%;
  }
}
@media (min-width: 1920px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-xl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-xl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-xl-1 {
    flex: 0 0 4.16666667%;
    max-width: 4.16666667%;
  }

  .col-xl-2 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }

  .col-xl-3 {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }

  .col-xl-4 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-xl-5 {
    flex: 0 0 20.83333333%;
    max-width: 20.83333333%;
  }

  .col-xl-6 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xl-7 {
    flex: 0 0 29.16666667%;
    max-width: 29.16666667%;
  }

  .col-xl-8 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-xl-9 {
    flex: 0 0 37.5%;
    max-width: 37.5%;
  }

  .col-xl-10 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-xl-11 {
    flex: 0 0 45.83333333%;
    max-width: 45.83333333%;
  }

  .col-xl-12 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-13 {
    flex: 0 0 54.16666667%;
    max-width: 54.16666667%;
  }

  .col-xl-14 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }

  .col-xl-15 {
    flex: 0 0 62.5%;
    max-width: 62.5%;
  }

  .col-xl-16 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }

  .col-xl-17 {
    flex: 0 0 70.83333333%;
    max-width: 70.83333333%;
  }

  .col-xl-18 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xl-19 {
    flex: 0 0 79.16666667%;
    max-width: 79.16666667%;
  }

  .col-xl-20 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }

  .col-xl-21 {
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }

  .col-xl-22 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }

  .col-xl-23 {
    flex: 0 0 95.83333333%;
    max-width: 95.83333333%;
  }

  .col-xl-24 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xl-first {
    order: -1;
  }

  .order-xl-last {
    order: 25;
  }

  .order-xl-0 {
    order: 0;
  }

  .order-xl-1 {
    order: 1;
  }

  .order-xl-2 {
    order: 2;
  }

  .order-xl-3 {
    order: 3;
  }

  .order-xl-4 {
    order: 4;
  }

  .order-xl-5 {
    order: 5;
  }

  .order-xl-6 {
    order: 6;
  }

  .order-xl-7 {
    order: 7;
  }

  .order-xl-8 {
    order: 8;
  }

  .order-xl-9 {
    order: 9;
  }

  .order-xl-10 {
    order: 10;
  }

  .order-xl-11 {
    order: 11;
  }

  .order-xl-12 {
    order: 12;
  }

  .order-xl-13 {
    order: 13;
  }

  .order-xl-14 {
    order: 14;
  }

  .order-xl-15 {
    order: 15;
  }

  .order-xl-16 {
    order: 16;
  }

  .order-xl-17 {
    order: 17;
  }

  .order-xl-18 {
    order: 18;
  }

  .order-xl-19 {
    order: 19;
  }

  .order-xl-20 {
    order: 20;
  }

  .order-xl-21 {
    order: 21;
  }

  .order-xl-22 {
    order: 22;
  }

  .order-xl-23 {
    order: 23;
  }

  .order-xl-24 {
    order: 24;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 4.16666667%;
  }

  .offset-xl-2 {
    margin-left: 8.33333333%;
  }

  .offset-xl-3 {
    margin-left: 12.5%;
  }

  .offset-xl-4 {
    margin-left: 16.66666667%;
  }

  .offset-xl-5 {
    margin-left: 20.83333333%;
  }

  .offset-xl-6 {
    margin-left: 25%;
  }

  .offset-xl-7 {
    margin-left: 29.16666667%;
  }

  .offset-xl-8 {
    margin-left: 33.33333333%;
  }

  .offset-xl-9 {
    margin-left: 37.5%;
  }

  .offset-xl-10 {
    margin-left: 41.66666667%;
  }

  .offset-xl-11 {
    margin-left: 45.83333333%;
  }

  .offset-xl-12 {
    margin-left: 50%;
  }

  .offset-xl-13 {
    margin-left: 54.16666667%;
  }

  .offset-xl-14 {
    margin-left: 58.33333333%;
  }

  .offset-xl-15 {
    margin-left: 62.5%;
  }

  .offset-xl-16 {
    margin-left: 66.66666667%;
  }

  .offset-xl-17 {
    margin-left: 70.83333333%;
  }

  .offset-xl-18 {
    margin-left: 75%;
  }

  .offset-xl-19 {
    margin-left: 79.16666667%;
  }

  .offset-xl-20 {
    margin-left: 83.33333333%;
  }

  .offset-xl-21 {
    margin-left: 87.5%;
  }

  .offset-xl-22 {
    margin-left: 91.66666667%;
  }

  .offset-xl-23 {
    margin-left: 95.83333333%;
  }
}
.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #007bff !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important;
}

.bg-success {
  background-color: #28a745 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #007bff !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 600px) {
  .d-sm-none {
    display: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 960px) {
  .d-md-none {
    display: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1280px) {
  .d-lg-none {
    display: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1920px) {
  .d-xl-none {
    display: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }

  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714286%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714286%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 600px) {
  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 960px) {
  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1280px) {
  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1920px) {
  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 600px) {
  .float-sm-left {
    float: left !important;
  }

  .float-sm-right {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 960px) {
  .float-md-left {
    float: left !important;
  }

  .float-md-right {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 1280px) {
  .float-lg-left {
    float: left !important;
  }

  .float-lg-right {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1920px) {
  .float-xl-left {
    float: left !important;
  }

  .float-xl-right {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }
}
.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 600px) {
  .m-sm-0 {
    margin: 0 !important;
  }

  .mt-sm-0,
.my-sm-0 {
    margin-top: 0 !important;
  }

  .mr-sm-0,
.mx-sm-0 {
    margin-right: 0 !important;
  }

  .mb-sm-0,
.my-sm-0 {
    margin-bottom: 0 !important;
  }

  .ml-sm-0,
.mx-sm-0 {
    margin-left: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .mt-sm-1,
.my-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mr-sm-1,
.mx-sm-1 {
    margin-right: 0.25rem !important;
  }

  .mb-sm-1,
.my-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-sm-1,
.mx-sm-1 {
    margin-left: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .mt-sm-2,
.my-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mr-sm-2,
.mx-sm-2 {
    margin-right: 0.5rem !important;
  }

  .mb-sm-2,
.my-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-sm-2,
.mx-sm-2 {
    margin-left: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .mt-sm-3,
.my-sm-3 {
    margin-top: 1rem !important;
  }

  .mr-sm-3,
.mx-sm-3 {
    margin-right: 1rem !important;
  }

  .mb-sm-3,
.my-sm-3 {
    margin-bottom: 1rem !important;
  }

  .ml-sm-3,
.mx-sm-3 {
    margin-left: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .mt-sm-4,
.my-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mr-sm-4,
.mx-sm-4 {
    margin-right: 1.5rem !important;
  }

  .mb-sm-4,
.my-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-sm-4,
.mx-sm-4 {
    margin-left: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .mt-sm-5,
.my-sm-5 {
    margin-top: 3rem !important;
  }

  .mr-sm-5,
.mx-sm-5 {
    margin-right: 3rem !important;
  }

  .mb-sm-5,
.my-sm-5 {
    margin-bottom: 3rem !important;
  }

  .ml-sm-5,
.mx-sm-5 {
    margin-left: 3rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .pt-sm-0,
.py-sm-0 {
    padding-top: 0 !important;
  }

  .pr-sm-0,
.px-sm-0 {
    padding-right: 0 !important;
  }

  .pb-sm-0,
.py-sm-0 {
    padding-bottom: 0 !important;
  }

  .pl-sm-0,
.px-sm-0 {
    padding-left: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .pt-sm-1,
.py-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pr-sm-1,
.px-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pb-sm-1,
.py-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-sm-1,
.px-sm-1 {
    padding-left: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .pt-sm-2,
.py-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pr-sm-2,
.px-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pb-sm-2,
.py-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-sm-2,
.px-sm-2 {
    padding-left: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .pt-sm-3,
.py-sm-3 {
    padding-top: 1rem !important;
  }

  .pr-sm-3,
.px-sm-3 {
    padding-right: 1rem !important;
  }

  .pb-sm-3,
.py-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pl-sm-3,
.px-sm-3 {
    padding-left: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .pt-sm-4,
.py-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pr-sm-4,
.px-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pb-sm-4,
.py-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-sm-4,
.px-sm-4 {
    padding-left: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .pt-sm-5,
.py-sm-5 {
    padding-top: 3rem !important;
  }

  .pr-sm-5,
.px-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-5,
.py-sm-5 {
    padding-bottom: 3rem !important;
  }

  .pl-sm-5,
.px-sm-5 {
    padding-left: 3rem !important;
  }

  .m-sm-n1 {
    margin: -0.25rem !important;
  }

  .mt-sm-n1,
.my-sm-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-sm-n1,
.mx-sm-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-sm-n1,
.my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-sm-n1,
.mx-sm-n1 {
    margin-left: -0.25rem !important;
  }

  .m-sm-n2 {
    margin: -0.5rem !important;
  }

  .mt-sm-n2,
.my-sm-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-sm-n2,
.mx-sm-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-sm-n2,
.my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-sm-n2,
.mx-sm-n2 {
    margin-left: -0.5rem !important;
  }

  .m-sm-n3 {
    margin: -1rem !important;
  }

  .mt-sm-n3,
.my-sm-n3 {
    margin-top: -1rem !important;
  }

  .mr-sm-n3,
.mx-sm-n3 {
    margin-right: -1rem !important;
  }

  .mb-sm-n3,
.my-sm-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-sm-n3,
.mx-sm-n3 {
    margin-left: -1rem !important;
  }

  .m-sm-n4 {
    margin: -1.5rem !important;
  }

  .mt-sm-n4,
.my-sm-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-sm-n4,
.mx-sm-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-sm-n4,
.my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-sm-n4,
.mx-sm-n4 {
    margin-left: -1.5rem !important;
  }

  .m-sm-n5 {
    margin: -3rem !important;
  }

  .mt-sm-n5,
.my-sm-n5 {
    margin-top: -3rem !important;
  }

  .mr-sm-n5,
.mx-sm-n5 {
    margin-right: -3rem !important;
  }

  .mb-sm-n5,
.my-sm-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-sm-n5,
.mx-sm-n5 {
    margin-left: -3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mt-sm-auto,
.my-sm-auto {
    margin-top: auto !important;
  }

  .mr-sm-auto,
.mx-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-auto,
.my-sm-auto {
    margin-bottom: auto !important;
  }

  .ml-sm-auto,
.mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 960px) {
  .m-md-0 {
    margin: 0 !important;
  }

  .mt-md-0,
.my-md-0 {
    margin-top: 0 !important;
  }

  .mr-md-0,
.mx-md-0 {
    margin-right: 0 !important;
  }

  .mb-md-0,
.my-md-0 {
    margin-bottom: 0 !important;
  }

  .ml-md-0,
.mx-md-0 {
    margin-left: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .mt-md-1,
.my-md-1 {
    margin-top: 0.25rem !important;
  }

  .mr-md-1,
.mx-md-1 {
    margin-right: 0.25rem !important;
  }

  .mb-md-1,
.my-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-md-1,
.mx-md-1 {
    margin-left: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .mt-md-2,
.my-md-2 {
    margin-top: 0.5rem !important;
  }

  .mr-md-2,
.mx-md-2 {
    margin-right: 0.5rem !important;
  }

  .mb-md-2,
.my-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-md-2,
.mx-md-2 {
    margin-left: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .mt-md-3,
.my-md-3 {
    margin-top: 1rem !important;
  }

  .mr-md-3,
.mx-md-3 {
    margin-right: 1rem !important;
  }

  .mb-md-3,
.my-md-3 {
    margin-bottom: 1rem !important;
  }

  .ml-md-3,
.mx-md-3 {
    margin-left: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .mt-md-4,
.my-md-4 {
    margin-top: 1.5rem !important;
  }

  .mr-md-4,
.mx-md-4 {
    margin-right: 1.5rem !important;
  }

  .mb-md-4,
.my-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-md-4,
.mx-md-4 {
    margin-left: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .mt-md-5,
.my-md-5 {
    margin-top: 3rem !important;
  }

  .mr-md-5,
.mx-md-5 {
    margin-right: 3rem !important;
  }

  .mb-md-5,
.my-md-5 {
    margin-bottom: 3rem !important;
  }

  .ml-md-5,
.mx-md-5 {
    margin-left: 3rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .pt-md-0,
.py-md-0 {
    padding-top: 0 !important;
  }

  .pr-md-0,
.px-md-0 {
    padding-right: 0 !important;
  }

  .pb-md-0,
.py-md-0 {
    padding-bottom: 0 !important;
  }

  .pl-md-0,
.px-md-0 {
    padding-left: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .pt-md-1,
.py-md-1 {
    padding-top: 0.25rem !important;
  }

  .pr-md-1,
.px-md-1 {
    padding-right: 0.25rem !important;
  }

  .pb-md-1,
.py-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-md-1,
.px-md-1 {
    padding-left: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .pt-md-2,
.py-md-2 {
    padding-top: 0.5rem !important;
  }

  .pr-md-2,
.px-md-2 {
    padding-right: 0.5rem !important;
  }

  .pb-md-2,
.py-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-md-2,
.px-md-2 {
    padding-left: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .pt-md-3,
.py-md-3 {
    padding-top: 1rem !important;
  }

  .pr-md-3,
.px-md-3 {
    padding-right: 1rem !important;
  }

  .pb-md-3,
.py-md-3 {
    padding-bottom: 1rem !important;
  }

  .pl-md-3,
.px-md-3 {
    padding-left: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .pt-md-4,
.py-md-4 {
    padding-top: 1.5rem !important;
  }

  .pr-md-4,
.px-md-4 {
    padding-right: 1.5rem !important;
  }

  .pb-md-4,
.py-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-md-4,
.px-md-4 {
    padding-left: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .pt-md-5,
.py-md-5 {
    padding-top: 3rem !important;
  }

  .pr-md-5,
.px-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-5,
.py-md-5 {
    padding-bottom: 3rem !important;
  }

  .pl-md-5,
.px-md-5 {
    padding-left: 3rem !important;
  }

  .m-md-n1 {
    margin: -0.25rem !important;
  }

  .mt-md-n1,
.my-md-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-md-n1,
.mx-md-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-md-n1,
.my-md-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-md-n1,
.mx-md-n1 {
    margin-left: -0.25rem !important;
  }

  .m-md-n2 {
    margin: -0.5rem !important;
  }

  .mt-md-n2,
.my-md-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-md-n2,
.mx-md-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-md-n2,
.my-md-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-md-n2,
.mx-md-n2 {
    margin-left: -0.5rem !important;
  }

  .m-md-n3 {
    margin: -1rem !important;
  }

  .mt-md-n3,
.my-md-n3 {
    margin-top: -1rem !important;
  }

  .mr-md-n3,
.mx-md-n3 {
    margin-right: -1rem !important;
  }

  .mb-md-n3,
.my-md-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-md-n3,
.mx-md-n3 {
    margin-left: -1rem !important;
  }

  .m-md-n4 {
    margin: -1.5rem !important;
  }

  .mt-md-n4,
.my-md-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-md-n4,
.mx-md-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-md-n4,
.my-md-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-md-n4,
.mx-md-n4 {
    margin-left: -1.5rem !important;
  }

  .m-md-n5 {
    margin: -3rem !important;
  }

  .mt-md-n5,
.my-md-n5 {
    margin-top: -3rem !important;
  }

  .mr-md-n5,
.mx-md-n5 {
    margin-right: -3rem !important;
  }

  .mb-md-n5,
.my-md-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-md-n5,
.mx-md-n5 {
    margin-left: -3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mt-md-auto,
.my-md-auto {
    margin-top: auto !important;
  }

  .mr-md-auto,
.mx-md-auto {
    margin-right: auto !important;
  }

  .mb-md-auto,
.my-md-auto {
    margin-bottom: auto !important;
  }

  .ml-md-auto,
.mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1280px) {
  .m-lg-0 {
    margin: 0 !important;
  }

  .mt-lg-0,
.my-lg-0 {
    margin-top: 0 !important;
  }

  .mr-lg-0,
.mx-lg-0 {
    margin-right: 0 !important;
  }

  .mb-lg-0,
.my-lg-0 {
    margin-bottom: 0 !important;
  }

  .ml-lg-0,
.mx-lg-0 {
    margin-left: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .mt-lg-1,
.my-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mr-lg-1,
.mx-lg-1 {
    margin-right: 0.25rem !important;
  }

  .mb-lg-1,
.my-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-lg-1,
.mx-lg-1 {
    margin-left: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .mt-lg-2,
.my-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mr-lg-2,
.mx-lg-2 {
    margin-right: 0.5rem !important;
  }

  .mb-lg-2,
.my-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-lg-2,
.mx-lg-2 {
    margin-left: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .mt-lg-3,
.my-lg-3 {
    margin-top: 1rem !important;
  }

  .mr-lg-3,
.mx-lg-3 {
    margin-right: 1rem !important;
  }

  .mb-lg-3,
.my-lg-3 {
    margin-bottom: 1rem !important;
  }

  .ml-lg-3,
.mx-lg-3 {
    margin-left: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .mt-lg-4,
.my-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mr-lg-4,
.mx-lg-4 {
    margin-right: 1.5rem !important;
  }

  .mb-lg-4,
.my-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-lg-4,
.mx-lg-4 {
    margin-left: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .mt-lg-5,
.my-lg-5 {
    margin-top: 3rem !important;
  }

  .mr-lg-5,
.mx-lg-5 {
    margin-right: 3rem !important;
  }

  .mb-lg-5,
.my-lg-5 {
    margin-bottom: 3rem !important;
  }

  .ml-lg-5,
.mx-lg-5 {
    margin-left: 3rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .pt-lg-0,
.py-lg-0 {
    padding-top: 0 !important;
  }

  .pr-lg-0,
.px-lg-0 {
    padding-right: 0 !important;
  }

  .pb-lg-0,
.py-lg-0 {
    padding-bottom: 0 !important;
  }

  .pl-lg-0,
.px-lg-0 {
    padding-left: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .pt-lg-1,
.py-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pr-lg-1,
.px-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pb-lg-1,
.py-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-lg-1,
.px-lg-1 {
    padding-left: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .pt-lg-2,
.py-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pr-lg-2,
.px-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pb-lg-2,
.py-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-lg-2,
.px-lg-2 {
    padding-left: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .pt-lg-3,
.py-lg-3 {
    padding-top: 1rem !important;
  }

  .pr-lg-3,
.px-lg-3 {
    padding-right: 1rem !important;
  }

  .pb-lg-3,
.py-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pl-lg-3,
.px-lg-3 {
    padding-left: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .pt-lg-4,
.py-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pr-lg-4,
.px-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pb-lg-4,
.py-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-lg-4,
.px-lg-4 {
    padding-left: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .pt-lg-5,
.py-lg-5 {
    padding-top: 3rem !important;
  }

  .pr-lg-5,
.px-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-5,
.py-lg-5 {
    padding-bottom: 3rem !important;
  }

  .pl-lg-5,
.px-lg-5 {
    padding-left: 3rem !important;
  }

  .m-lg-n1 {
    margin: -0.25rem !important;
  }

  .mt-lg-n1,
.my-lg-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-lg-n1,
.mx-lg-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-lg-n1,
.my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-lg-n1,
.mx-lg-n1 {
    margin-left: -0.25rem !important;
  }

  .m-lg-n2 {
    margin: -0.5rem !important;
  }

  .mt-lg-n2,
.my-lg-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-lg-n2,
.mx-lg-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-lg-n2,
.my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-lg-n2,
.mx-lg-n2 {
    margin-left: -0.5rem !important;
  }

  .m-lg-n3 {
    margin: -1rem !important;
  }

  .mt-lg-n3,
.my-lg-n3 {
    margin-top: -1rem !important;
  }

  .mr-lg-n3,
.mx-lg-n3 {
    margin-right: -1rem !important;
  }

  .mb-lg-n3,
.my-lg-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-lg-n3,
.mx-lg-n3 {
    margin-left: -1rem !important;
  }

  .m-lg-n4 {
    margin: -1.5rem !important;
  }

  .mt-lg-n4,
.my-lg-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-lg-n4,
.mx-lg-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-lg-n4,
.my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-lg-n4,
.mx-lg-n4 {
    margin-left: -1.5rem !important;
  }

  .m-lg-n5 {
    margin: -3rem !important;
  }

  .mt-lg-n5,
.my-lg-n5 {
    margin-top: -3rem !important;
  }

  .mr-lg-n5,
.mx-lg-n5 {
    margin-right: -3rem !important;
  }

  .mb-lg-n5,
.my-lg-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-lg-n5,
.mx-lg-n5 {
    margin-left: -3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mt-lg-auto,
.my-lg-auto {
    margin-top: auto !important;
  }

  .mr-lg-auto,
.mx-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-auto,
.my-lg-auto {
    margin-bottom: auto !important;
  }

  .ml-lg-auto,
.mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1920px) {
  .m-xl-0 {
    margin: 0 !important;
  }

  .mt-xl-0,
.my-xl-0 {
    margin-top: 0 !important;
  }

  .mr-xl-0,
.mx-xl-0 {
    margin-right: 0 !important;
  }

  .mb-xl-0,
.my-xl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xl-0,
.mx-xl-0 {
    margin-left: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .mt-xl-1,
.my-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mr-xl-1,
.mx-xl-1 {
    margin-right: 0.25rem !important;
  }

  .mb-xl-1,
.my-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-xl-1,
.mx-xl-1 {
    margin-left: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .mt-xl-2,
.my-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mr-xl-2,
.mx-xl-2 {
    margin-right: 0.5rem !important;
  }

  .mb-xl-2,
.my-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-xl-2,
.mx-xl-2 {
    margin-left: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .mt-xl-3,
.my-xl-3 {
    margin-top: 1rem !important;
  }

  .mr-xl-3,
.mx-xl-3 {
    margin-right: 1rem !important;
  }

  .mb-xl-3,
.my-xl-3 {
    margin-bottom: 1rem !important;
  }

  .ml-xl-3,
.mx-xl-3 {
    margin-left: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .mt-xl-4,
.my-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mr-xl-4,
.mx-xl-4 {
    margin-right: 1.5rem !important;
  }

  .mb-xl-4,
.my-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-xl-4,
.mx-xl-4 {
    margin-left: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .mt-xl-5,
.my-xl-5 {
    margin-top: 3rem !important;
  }

  .mr-xl-5,
.mx-xl-5 {
    margin-right: 3rem !important;
  }

  .mb-xl-5,
.my-xl-5 {
    margin-bottom: 3rem !important;
  }

  .ml-xl-5,
.mx-xl-5 {
    margin-left: 3rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .pt-xl-0,
.py-xl-0 {
    padding-top: 0 !important;
  }

  .pr-xl-0,
.px-xl-0 {
    padding-right: 0 !important;
  }

  .pb-xl-0,
.py-xl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xl-0,
.px-xl-0 {
    padding-left: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .pt-xl-1,
.py-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pr-xl-1,
.px-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pb-xl-1,
.py-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-xl-1,
.px-xl-1 {
    padding-left: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .pt-xl-2,
.py-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pr-xl-2,
.px-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pb-xl-2,
.py-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-xl-2,
.px-xl-2 {
    padding-left: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .pt-xl-3,
.py-xl-3 {
    padding-top: 1rem !important;
  }

  .pr-xl-3,
.px-xl-3 {
    padding-right: 1rem !important;
  }

  .pb-xl-3,
.py-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pl-xl-3,
.px-xl-3 {
    padding-left: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .pt-xl-4,
.py-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pr-xl-4,
.px-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pb-xl-4,
.py-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-xl-4,
.px-xl-4 {
    padding-left: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .pt-xl-5,
.py-xl-5 {
    padding-top: 3rem !important;
  }

  .pr-xl-5,
.px-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-5,
.py-xl-5 {
    padding-bottom: 3rem !important;
  }

  .pl-xl-5,
.px-xl-5 {
    padding-left: 3rem !important;
  }

  .m-xl-n1 {
    margin: -0.25rem !important;
  }

  .mt-xl-n1,
.my-xl-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-xl-n1,
.mx-xl-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-xl-n1,
.my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-xl-n1,
.mx-xl-n1 {
    margin-left: -0.25rem !important;
  }

  .m-xl-n2 {
    margin: -0.5rem !important;
  }

  .mt-xl-n2,
.my-xl-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-xl-n2,
.mx-xl-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-xl-n2,
.my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-xl-n2,
.mx-xl-n2 {
    margin-left: -0.5rem !important;
  }

  .m-xl-n3 {
    margin: -1rem !important;
  }

  .mt-xl-n3,
.my-xl-n3 {
    margin-top: -1rem !important;
  }

  .mr-xl-n3,
.mx-xl-n3 {
    margin-right: -1rem !important;
  }

  .mb-xl-n3,
.my-xl-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-xl-n3,
.mx-xl-n3 {
    margin-left: -1rem !important;
  }

  .m-xl-n4 {
    margin: -1.5rem !important;
  }

  .mt-xl-n4,
.my-xl-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-xl-n4,
.mx-xl-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-xl-n4,
.my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-xl-n4,
.mx-xl-n4 {
    margin-left: -1.5rem !important;
  }

  .m-xl-n5 {
    margin: -3rem !important;
  }

  .mt-xl-n5,
.my-xl-n5 {
    margin-top: -3rem !important;
  }

  .mr-xl-n5,
.mx-xl-n5 {
    margin-right: -3rem !important;
  }

  .mb-xl-n5,
.my-xl-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-xl-n5,
.mx-xl-n5 {
    margin-left: -3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mt-xl-auto,
.my-xl-auto {
    margin-top: auto !important;
  }

  .mr-xl-auto,
.mx-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-auto,
.my-xl-auto {
    margin-bottom: auto !important;
  }

  .ml-xl-auto,
.mx-xl-auto {
    margin-left: auto !important;
  }
}
.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 600px) {
  .text-sm-left {
    text-align: left !important;
  }

  .text-sm-right {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 960px) {
  .text-md-left {
    text-align: left !important;
  }

  .text-md-right {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 1280px) {
  .text-lg-left {
    text-align: left !important;
  }

  .text-lg-right {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1920px) {
  .text-xl-left {
    text-align: left !important;
  }

  .text-xl-right {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #007bff !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #0056b3 !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #19692c !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

/* mixin for multiline */
.b-none {
  border: none !important;
}

.cursor-pointer {
  cursor: pointer;
}

.z-1 {
  z-index: 1;
}

/* mixin for multiline */
.b-none {
  border: none !important;
}

.cursor-pointer {
  cursor: pointer;
}

.z-1 {
  z-index: 1;
}

@font-face {
  font-family: "Ivy Mode";
  src: url(/assets/fonts/IvyMode.woff2);
}
@font-face {
  font-family: "Futura";
  src: url(/assets/fonts/Futura.ttf);
}
@font-face {
  font-family: "Futura";
  src: url(/assets/fonts/Futura-bold.ttf);
  font-weight: bold;
}
.mat-typography h1 {
  font-family: "Ivy Mode";
  font-weight: 400;
  font-size: 36px;
  font-size: 2.25rem;
  line-height: 25px;
  line-height: 1.5625rem;
}
@media screen and (max-width: 960px) {
  .mat-typography h1 {
    font-size: 36px;
    font-size: 2.25rem;
    line-height: 25px;
    line-height: 1.5625rem;
  }
}

.mat-typography h2 {
  font-family: "Ivy Mode";
  font-weight: 400;
  font-size: 30px;
  font-size: 1.875rem;
  line-height: 22px;
  line-height: 1.375rem;
}
@media screen and (max-width: 960px) {
  .mat-typography h2 {
    font-size: 30px;
    font-size: 1.875rem;
    line-height: 22px;
    line-height: 1.375rem;
  }
}

.mat-typography h3 {
  font-family: futura;
  font-weight: 600;
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 20px;
  line-height: 1.25rem;
}
@media screen and (max-width: 960px) {
  .mat-typography h3 {
    font-size: 18px;
    font-size: 1.125rem;
    line-height: 20px;
    line-height: 1.25rem;
  }
}

.mat-typography h4 {
  font-family: "Ivy Mode";
  font-weight: 400;
  font-size: 36px;
  font-size: 2.25rem;
  line-height: 43px;
  line-height: 2.6875rem;
}
@media screen and (max-width: 960px) {
  .mat-typography h4 {
    font-size: 28px;
    font-size: 1.75rem;
    line-height: 33px;
    line-height: 2.0625rem;
  }
}

.mat-typography h5 {
  font-family: "Ivy Mode";
  font-weight: 400;
  font-size: 11px;
  font-size: 0.6875rem;
  line-height: 15px;
  line-height: 0.9375rem;
}
@media screen and (max-width: 960px) {
  .mat-typography h5 {
    font-size: 11px;
    font-size: 0.6875rem;
    line-height: 15px;
    line-height: 0.9375rem;
  }
}

.mat-typography h6 {
  font-family: futura;
  font-weight: 700;
  font-size: 15px;
  font-size: 0.9375rem;
  line-height: 18px;
  line-height: 1.125rem;
}
@media screen and (max-width: 960px) {
  .mat-typography h6 {
    font-size: 15px;
    font-size: 0.9375rem;
    line-height: 18px;
    line-height: 1.125rem;
  }
}

div,
p,
span {
  font-family: "Futura";
  font-weight: 400;
  font-size: 16px;
  font-size: 1rem;
  line-height: 28px;
  line-height: 1.75rem;
}

/*!
 * Font Awesome Pro 5.10.1 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
@font-face {
  font-family: "Font Awesome 5 Brands";
  font-style: normal;
  font-weight: 400;
  src: url(/assets/fonts/fa-brands-400.woff2) format("woff2");
}
.fab {
  font-family: "Font Awesome 5 Brands";
}

* {
  box-sizing: border-box;
  outline: none;
}

html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

*[hidden] {
  display: none !important;
}

/*--------------------------------------------------------------
# Navigation
--------------------------------------------------------------*/
/*--------------------------------------------------------------
## Links
--------------------------------------------------------------*/
.brands-list {
  column-count: 3;
  height: 130px;
  column-fill: auto;
  column-width: 170px;
}

a {
  color: rgb(255, 255, 255);
  font-size: 15px !important;
  font-weight: 400;
  text-decoration: none !important;
  font-family: "Futura";
  line-height: 24px !important;
}

a:visited {
  color: #333;
}

a:hover,
a:focus,
a:active {
  color: #e927c1;
}

a:focus {
  outline: thin dotted;
}

a:hover,
a:active {
  outline: 0;
}

.no-link > a {
  cursor: inherit;
}

/*--------------------------------------------------------------
## Menus
--------------------------------------------------------------*/
.primary-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 400px;
  max-width: 100%;
  margin: 0 auto;
  list-style: none;
  padding-top: 20px;
}
.primary-menu__item > a {
  display: block;
  padding: 10px;
  color: rgb(255, 255, 255);
  font-size: 15px !important;
  font-weight: 400;
  font-family: "Futura";
  line-height: 24px !important;
  cursor: pointer;
}
.primary-menu__item:hover > a {
  text-decoration: underline !important;
}
.primary-menu__mega-menu {
  position: absolute;
  top: calc(100% + 1px);
  left: 0;
  z-index: 9999;
  width: 100%;
  max-height: 0;
  background-color: rgb(0, 0, 0);
  overflow: hidden;
}
.primary-menu__mega-menu.active {
  max-height: 176px;
  height: 176px;
}
.primary-menu__sub-menu {
  width: 600px;
  padding: 30px 10px 40px;
  margin: 0 auto;
  color: rgb(255, 255, 255);
  list-style: none;
  text-align: left;
}
.primary-menu__sub-item {
  display: inline-block;
  vertical-align: top;
}
.primary-menu__column {
  margin: 0;
  list-style: none;
}
.primary-menu__column-item.nosocial {
  margin-bottom: 10px;
}
.primary-menu__column-item > a {
  display: block;
  padding: 5px 0;
  color: rgb(255, 255, 255);
  font-size: 15px;
}
.primary-menu__column-item:hover > a {
  text-decoration: underline !important;
  cursor: pointer;
}
.primary-menu__item.social p {
  margin-bottom: 15px;
}
.primary-menu__item.social .social {
  padding: 0;
  margin: 0;
  list-style: none;
}
.primary-menu__item.social .social li {
  display: inline-block;
  margin-right: 15px;
}
.primary-menu__item.social .social li a {
  color: rgb(255, 255, 255);
}
.primary-menu__item.social .social li a:hover {
  opacity: 0.5;
}
.primary-menu__item.social .social li a:focus {
  outline: 1px dotted;
}

.primary-menu__item.has-mega-menu.hover .primary-menu__mega-menu, .primary-menu__item.has-mega-menu:hover .primary-menu__mega-menu, .primary-menu__item.social.hover .primary-menu__mega-menu, .primary-menu__item.social:hover .primary-menu__mega-menu {
  max-height: 176px;
  height: 176px;
}

@media screen and (max-width: 600px) {
  .primary-menu {
    flex-direction: column;
    align-items: flex-start;
    padding: 5px 35px;
    text-align: left;
  }
  .primary-menu__mega-menu {
    position: static;
    max-height: none;
    background: none;
  }
  .primary-menu__item {
    margin-bottom: 20px;
  }
  .primary-menu__item > a {
    padding: 5px 10px;
  }
  .primary-menu__item.search .primary-menu__mega-menu {
    display: none;
  }
  .primary-menu__item.search .primary-menu__mega-menu.active {
    position: fixed;
    top: 95;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    width: 100%;
    padding: 50px 45px 0;
    background-color: rgb(0, 0, 0);
  }
  .primary-menu__item.search .primary-menu__mega-menu.active p {
    display: block;
  }
  .primary-menu__item.search h4 {
    text-align: left;
  }
  .primary-menu__item.search ul {
    flex-direction: column;
    align-items: flex-start;
    padding: 0 0 0 20px;
  }
  .primary-menu__item.search ul a:hover {
    text-decoration: underline;
  }
  .primary-menu__item.search ul li:not(:last-child)::after {
    display: none;
  }
  .primary-menu__item.social .social {
    padding-left: 10px;
    margin-top: 50px;
  }
  .primary-menu__item.social .social li a {
    font-size: 26px;
  }
  .primary-menu__mega-menu.active {
    max-height: none;
    height: auto;
  }
  .primary-menu__sub-menu {
    width: auto;
    padding: 0 0 0 20px;
  }
  .primary-menu__sub-item {
    display: block;
  }
  .primary-menu__sub-item > p {
    display: none;
  }
  .primary-menu__column {
    padding-left: 0px;
    margin: 0;
    list-style: none;
  }
  .primary-menu__column-item > a {
    padding: 5px 10px;
    font-size: 14px !important;
    font-weight: 10;
  }
}
/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
.header {
  position: relative;
  width: 100%;
  padding-top: 20px;
  background-color: rgb(0, 0, 0);
  border-bottom: 1px solid #4a4a4a;
  text-align: center;
}
.header--sidenav {
  border-bottom: none;
}

.logo {
  height: auto;
  max-width: 100%;
}

.mat-icon-button {
  color: rgb(255, 255, 255) !important;
}

@media screen and (max-width: 600px) {
  .header {
    border-bottom: none !important;
    padding-bottom: 10px;
  }

  .logo {
    height: auto;
    max-width: 71%;
    padding-top: 10px;
  }
}
/*--------------------------------------------------------------
# Two Sliders Section
--------------------------------------------------------------*/
.two-sliders {
  width: 100%;
  padding: 70px 0;
  margin-bottom: -410px;
  background-image: url(/assets/img/home/background-two-sliders.jpg);
  background-repeat: no-repeat;
  background-position: right -7px top 20px;
  overflow: hidden;
}
.two-sliders__first-slider {
  position: relative;
  height: 240px;
  margin-bottom: 10px;
}
.two-sliders__second-slider {
  position: relative;
  height: 240px;
}
.two-sliders__content {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: flex-start;
  min-width: 100%;
  height: 100%;
  transform: translateX(0);
  animation: slide 50s linear infinite;
}
.two-sliders__content mks-glitter-image {
  display: inline-block;
  margin-right: 10px;
}
.two-sliders__second-content {
  position: absolute;
  top: 0;
  left: 100%;
  display: flex;
  justify-content: flex-start;
  min-width: 100%;
  height: 100%;
  transform: translateX(0);
  animation: slide 50s linear infinite;
}
.two-sliders__second-content mks-glitter-image {
  display: inline-block;
  margin-right: 10px;
}
.two-sliders__first-slider .two-sliders__second-content {
  left: -100%;
}
.two-sliders__second-slider .two-sliders__content {
  justify-content: flex-end;
  animation: slide-reverse 50s linear infinite;
}
.two-sliders__second-slider .two-sliders__content mks-glitter-image {
  margin-left: 10px;
  margin-right: 0;
}
.two-sliders__second-slider .two-sliders__second-content {
  justify-content: flex-end;
  animation: slide-reverse 50s linear infinite;
}
.two-sliders__second-slider .two-sliders__second-content mks-glitter-image {
  margin-left: 10px;
  margin-right: 0;
}

@keyframes slide {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
}
@keyframes slide-reverse {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}
@media screen and (max-width: 600px) {
  .two-sliders {
    display: none;
  }
}
@media screen and (max-width: 960px) {
  .container {
    max-width: none;
  }
}
@media screen and (min-width: 960px) {
  .container {
    max-width: 910px;
  }
}

.mat-table {
  border: 2px solid rgb(0, 0, 0);
}
.mat-table .mat-header-row {
  background: rgb(0, 0, 0);
}
.mat-table .mat-header-row .mat-header-cell {
  color: rgb(255, 255, 255);
}

.mat-row:nth-child(even) {
  background-color: rgb(245, 245, 245);
}

a.mks-button {
  border: 2px solid #000;
  border-radius: 0;
  padding: 14px 50px;
  margin-bottom: 50px;
  text-transform: uppercase;
}
a.mks-button .mat-button-wrapper {
  font-size: 14px;
  line-height: 18px;
  font-weight: bold;
}

.mat-sidenav {
  background-color: #000 !important;
}

.bg-black {
  background-color: #000;
}

.left-0 {
  left: 0;
}

.right-0 {
  right: 0;
}

.pointer {
  cursor: pointer;
}

.brand-description-text {
  width: 300px;
}
@media screen and (max-width: 600px) {
  .brand-description-text {
    text-align: center !important;
  }
}

.brand-detail-container {
  margin-top: -150px;
}
@media screen and (max-width: 600px) {
  .brand-detail-container {
    text-align: center !important;
  }
}

.button {
  display: block;
  width: 155px;
  margin: 0 auto;
  padding: 10px;
  border: 2px solid rgb(0, 0, 0);
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 1px;
  font-size: 11px !important;
  text-align: center;
  font-family: "Futura";
  font-weight: bold;
  transition: all 0.4s ease;
}
.button:hover, .button:focus {
  transition: all 0.4s ease;
  text-decoration: none;
}
.button:focus {
  outline: 1px dashed rgb(0, 0, 0);
}
.button.black-btn {
  border-color: rgb(0, 0, 0);
  color: rgb(0, 0, 0);
}
.button.black-btn:hover {
  background-color: rgb(0, 0, 0);
  color: rgb(255, 255, 255);
}
.button.black-btn:focus {
  opacity: 0.85;
  outline: 1px dashed rgb(0, 0, 0);
}
.button.white-btn {
  border-color: rgb(255, 255, 255);
  color: rgb(255, 255, 255);
}
.button.white-btn:hover {
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
}
.button.white-btn:focus {
  opacity: 0.85;
  outline: 1px dashed rgb(255, 255, 255);
}

.bitf-dnd-droppable-area {
  background-color: #4d4d4d;
  border: solid 1px black;
  border-radius: 5px;
  height: 60px;
  transition: background-color ease-in 0.6s;
}
.bitf-dnd-droppable-area.bitf-dnd-file-is-dragging {
  background-color: #f068d4;
}

/*--------------------------------------------------------------
# Map
--------------------------------------------------------------*/
.map {
  position: relative;
  width: 690px;
  height: 338px;
  margin: 0 auto 60px;
}
.map__body {
  display: flex;
}
.map__markers {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.map__marker {
  position: absolute;
  width: 8px;
  height: 8px;
  top: 0;
  left: 0;
  background-color: #e01084;
  cursor: pointer;
  transition: 0.3s;
}
.map__marker-content {
  padding: 15px;
  position: absolute;
  width: 168px;
  height: 168px;
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 50%;
  overflow: hidden;
  transition: 0.3s;
  font-size: 13px;
  line-height: 1.6;
  visibility: hidden;
  transform: translateX(-50%) translateY(-50%) scale(0.25);
  background-color: #e01084;
  color: #fff;
}
.map__marker-content h4 {
  font-size: 13px;
  color: #fff;
  text-decoration: underline;
  text-transform: uppercase;
  font: 700 18px/26px "Futura", sans-serif;
  letter-spacing: 0.5px;
  margin: 0;
  font-size: 13px;
}
.map__marker-content strong {
  text-transform: uppercase;
  font-size: 13px;
}
.map__marker-content p {
  margin: 0;
  font-size: 13px;
}
.map__break {
  display: none;
}
.map__item {
  display: inline-block;
  width: 8px;
  height: 8px;
  margin: 0 3px 0 0;
  background-color: #c7c7c7;
}
.map__item:last-child {
  margin-right: 0;
}
.map__item:first-child, .map__item:nth-child(2), .map__item:nth-child(3), .map__item:nth-child(4), .map__item:nth-child(5) {
  margin-top: 55px;
  box-shadow: 0 11px 0 #c7c7c7, 0 22px 0 #c7c7c7;
}
.map__item:nth-child(6) {
  margin-top: 55px;
  box-shadow: 0 11px 0 #c7c7c7, 0 22px 0 #c7c7c7, 0 33px 0 #c7c7c7;
}
.map__item:nth-child(7) {
  margin-top: 55px;
  box-shadow: 0 11px 0 #c7c7c7, 0 22px 0 #c7c7c7, 0 33px 0 #c7c7c7, 0 44px 0 #c7c7c7;
}
.map__item:nth-child(8) {
  margin-top: 44px;
  box-shadow: 0 11px 0 #c7c7c7, 0 22px 0 #c7c7c7, 0 33px 0 #c7c7c7, 0 44px 0 #c7c7c7, 0 55px 0 #c7c7c7, 0 66px 0 #c7c7c7, 0 88px 0 #c7c7c7;
}
.map__item:nth-child(9) {
  margin-top: 44px;
  box-shadow: 0 11px 0 #c7c7c7, 0 22px 0 #c7c7c7, 0 33px 0 #c7c7c7, 0 44px 0 #c7c7c7, 0 55px 0 #c7c7c7, 0 66px 0 #c7c7c7, 0 77px 0 #c7c7c7, 0 88px 0 #c7c7c7, 0 99px 0 #c7c7c7;
}
.map__item:nth-child(10) {
  margin-top: 22px;
  box-shadow: 0 11px 0 #c7c7c7, 0 22px 0 #c7c7c7, 0 33px 0 #c7c7c7, 0 44px 0 #c7c7c7, 0 55px 0 #c7c7c7, 0 66px 0 #c7c7c7, 0 77px 0 #c7c7c7, 0 88px 0 #c7c7c7, 0 99px 0 #c7c7c7, 0 110px 0 #c7c7c7, 0 121px 0 #c7c7c7, 0 132px 0 #c7c7c7;
}
.map__item:nth-child(11) {
  margin-top: 33px;
  box-shadow: 0 11px 0 #c7c7c7, 0 22px 0 #c7c7c7, 0 33px 0 #c7c7c7, 0 44px 0 #c7c7c7, 0 55px 0 #c7c7c7, 0 66px 0 #c7c7c7, 0 77px 0 #c7c7c7, 0 88px 0 #c7c7c7, 0 99px 0 #c7c7c7, 0 110px 0 #c7c7c7, 0 121px 0 #c7c7c7, 0 132px 0 #c7c7c7;
}
.map__item:nth-child(12) {
  margin-top: 66px;
  box-shadow: 0 11px 0 #c7c7c7, 0 22px 0 #c7c7c7, 0 33px 0 #c7c7c7, 0 44px 0 #c7c7c7, 0 55px 0 #c7c7c7, 0 66px 0 #c7c7c7, 0 77px 0 #c7c7c7, 0 88px 0 #c7c7c7, 0 99px 0 #c7c7c7, 0 110px 0 #c7c7c7;
}
.map__item:nth-child(13) {
  margin-top: 11px;
  box-shadow: 0 11px 0 #c7c7c7, 0 22px 0 #c7c7c7, 0 44px 0 #c7c7c7, 0 55px 0 #c7c7c7, 0 66px 0 #c7c7c7, 0 77px 0 #c7c7c7, 0 88px 0 #c7c7c7, 0 99px 0 #c7c7c7, 0 110px 0 #c7c7c7, 0 121px 0 #c7c7c7, 0 132px 0 #c7c7c7, 0 143px 0 #c7c7c7;
}
.map__item:nth-child(14) {
  margin-top: 11px;
  box-shadow: 0 22px 0 #c7c7c7, 0 44px 0 #c7c7c7, 0 55px 0 #c7c7c7, 0 88px 0 #c7c7c7, 0 99px 0 #c7c7c7, 0 110px 0 #c7c7c7, 0 121px 0 #c7c7c7, 0 132px 0 #c7c7c7, 0 143px 0 #c7c7c7, 0 165px 0 #c7c7c7, 0 176px 0 #c7c7c7;
}
.map__item:nth-child(15) {
  box-shadow: 0 11px 0 #c7c7c7, 0 22px 0 #c7c7c7, 0 33px 0 #c7c7c7, 0 44px 0 #c7c7c7, 0 55px 0 #c7c7c7, 0 66px 0 #c7c7c7, 0 99px 0 #c7c7c7, 0 110px 0 #c7c7c7, 0 121px 0 #c7c7c7, 0 132px 0 #c7c7c7, 0 143px 0 #c7c7c7, 0 154px 0 #c7c7c7, 0 187px 0 #c7c7c7;
}
.map__item:nth-child(16) {
  box-shadow: 0 11px 0 #c7c7c7, 0 22px 0 #c7c7c7, 0 44px 0 #c7c7c7, 0 77px 0 #c7c7c7, 0 99px 0 #c7c7c7, 0 110px 0 #c7c7c7, 0 121px 0 #c7c7c7, 0 132px 0 #c7c7c7, 0 143px 0 #c7c7c7, 0 198px 0 #c7c7c7, 0 220px 0 #c7c7c7, 0 231px 0 #c7c7c7;
}
.map__item:nth-child(17) {
  box-shadow: 0 11px 0 #c7c7c7, 0 55px 0 #c7c7c7, 0 66px 0 #c7c7c7, 0 77px 0 #c7c7c7, 0 88px 0 #c7c7c7, 0 99px 0 #c7c7c7, 0 110px 0 #c7c7c7, 0 121px 0 #c7c7c7, 0 132px 0 #c7c7c7, 0 198px 0 #c7c7c7, 0 209px 0 #c7c7c7, 0 220px 0 #c7c7c7, 0 231px 0 #c7c7c7, 0 242px 0 #c7c7c7, 0 297px 0 #c7c7c7, 0 308px 0 #c7c7c7, 0 319px 0 #c7c7c7, 0 330px 0 #c7c7c7;
}
.map__item:nth-child(18) {
  box-shadow: 0 11px 0 #c7c7c7, 0 22px 0 #c7c7c7, 0 66px 0 #c7c7c7, 0 88px 0 #c7c7c7, 0 99px 0 #c7c7c7, 0 110px 0 #c7c7c7, 0 121px 0 #c7c7c7, 0 198px 0 #c7c7c7, 0 209px 0 #c7c7c7, 0 220px 0 #c7c7c7, 0 231px 0 #c7c7c7, 0 242px 0 #c7c7c7, 0 253px 0 #c7c7c7, 0 264px 0 #c7c7c7, 0 275px 0 #c7c7c7, 0 286px 0 #c7c7c7, 0 297px 0 #c7c7c7, 0 308px 0 #c7c7c7, 0 319px 0 #c7c7c7, 0 330px 0 #c7c7c7;
}
.map__item:nth-child(19) {
  box-shadow: 0 11px 0 #c7c7c7, 0 22px 0 #c7c7c7, 0 88px 0 #c7c7c7, 0 99px 0 #c7c7c7, 0 121px 0 #c7c7c7, 0 198px 0 #c7c7c7, 0 209px 0 #c7c7c7, 0 220px 0 #c7c7c7, 0 231px 0 #c7c7c7, 0 242px 0 #c7c7c7, 0 253px 0 #c7c7c7, 0 264px 0 #c7c7c7, 0 275px 0 #c7c7c7, 0 286px 0 #c7c7c7, 0 297px 0 #c7c7c7;
}
.map__item:nth-child(20) {
  margin-top: 11px;
  box-shadow: 0 11px 0 #c7c7c7, 0 22px 0 #c7c7c7, 0 99px 0 #c7c7c7, 0 198px 0 #c7c7c7, 0 209px 0 #c7c7c7, 0 220px 0 #c7c7c7, 0 231px 0 #c7c7c7, 0 242px 0 #c7c7c7, 0 253px 0 #c7c7c7, 0 264px 0 #c7c7c7;
}
.map__item:nth-child(21) {
  margin-top: 11px;
  box-shadow: 0 11px 0 #c7c7c7, 0 22px 0 #c7c7c7, 0 33px 0 #c7c7c7, 0 44px 0 #c7c7c7, 0 55px 0 #c7c7c7, 0 198px 0 #c7c7c7, 0 209px 0 #c7c7c7, 0 220px 0 #c7c7c7, 0 231px 0 #c7c7c7, 0 242px 0 #c7c7c7, 0 253px 0 #c7c7c7, 0 264px 0 #c7c7c7;
}
.map__item:nth-child(22) {
  box-shadow: 0 11px 0 #c7c7c7, 0 22px 0 #c7c7c7, 0 33px 0 #c7c7c7, 0 44px 0 #c7c7c7, 0 55px 0 #c7c7c7, 0 66px 0 #c7c7c7, 0 77px 0 #c7c7c7, 0 220px 0 #c7c7c7, 0 231px 0 #c7c7c7, 0 242px 0 #c7c7c7, 0 253px 0 #c7c7c7;
}
.map__item:nth-child(23) {
  box-shadow: 0 11px 0 #c7c7c7, 0 22px 0 #c7c7c7, 0 33px 0 #c7c7c7, 0 44px 0 #c7c7c7, 0 55px 0 #c7c7c7, 0 66px 0 #c7c7c7, 0 231px 0 #c7c7c7, 0 242px 0 #c7c7c7, 0 253px 0 #c7c7c7;
}
.map__item:nth-child(24), .map__item:nth-child(25) {
  box-shadow: 0 11px 0 #c7c7c7, 0 22px 0 #c7c7c7, 0 33px 0 #c7c7c7, 0 44px 0 #c7c7c7, 0 55px 0 #c7c7c7;
}
.map__item:nth-child(26) {
  box-shadow: 0 11px 0 #c7c7c7, 0 22px 0 #c7c7c7, 0 33px 0 #c7c7c7, 0 66px 0 #c7c7c7;
}
.map__item:nth-child(27) {
  margin-top: 66px;
  box-shadow: 0 121px 0 #c7c7c7;
}
.map__item:nth-child(29) {
  margin-top: 165px;
  box-shadow: 0 11px 0 #c7c7c7, 0 22px 0 #c7c7c7, 0 33px 0 #c7c7c7;
}
.map__item:nth-child(30) {
  margin-top: 99px;
  box-shadow: 0 33px 0 #c7c7c7, 0 44px 0 #c7c7c7, 0 55px 0 #c7c7c7, 0 66px 0 #c7c7c7, 0 77px 0 #c7c7c7, 0 88px 0 #c7c7c7, 0 99px 0 #c7c7c7;
}
.map__item:nth-child(31) {
  margin-top: 99px;
  box-shadow: 0 22px 0 #c7c7c7, 0 33px 0 #c7c7c7, 0 44px 0 #c7c7c7, 0 55px 0 #c7c7c7, 0 66px 0 #c7c7c7, 0 77px 0 #c7c7c7, 0 88px 0 #c7c7c7, 0 99px 0 #c7c7c7;
}
.map__item:nth-child(32) {
  margin-top: 77px;
  box-shadow: 0 33px 0 #c7c7c7, 0 44px 0 #c7c7c7, 0 66px 0 #c7c7c7, 0 77px 0 #c7c7c7, 0 88px 0 #c7c7c7, 0 99px 0 #c7c7c7, 0 110px 0 #c7c7c7, 0 121px 0 #c7c7c7;
}
.map__item:nth-child(33) {
  margin-top: 77px;
  box-shadow: 0 22px 0 #c7c7c7, 0 33px 0 #c7c7c7, 0 44px 0 #c7c7c7, 0 77px 0 #c7c7c7, 0 88px 0 #c7c7c7, 0 99px 0 #c7c7c7, 0 110px 0 #c7c7c7, 0 121px 0 #c7c7c7, 0 132px 0 #c7c7c7, 0 143px 0 #c7c7c7;
}
.map__item:nth-child(34) {
  margin-top: 22px;
  box-shadow: 0 33px 0 #c7c7c7, 0 44px 0 #c7c7c7, 0 55px 0 #c7c7c7, 0 66px 0 #c7c7c7, 0 77px 0 #c7c7c7, 0 88px 0 #c7c7c7, 0 99px 0 #c7c7c7, 0 110px 0 #c7c7c7, 0 132px 0 #c7c7c7, 0 143px 0 #c7c7c7, 0 154px 0 #c7c7c7, 0 165px 0 #c7c7c7, 0 176px 0 #c7c7c7, 0 187px 0 #c7c7c7, 0 198px 0 #c7c7c7, 0 209px 0 #c7c7c7, 0 220px 0 #c7c7c7, 0 231px 0 #c7c7c7, 0 242px 0 #c7c7c7;
}
.map__item:nth-child(35) {
  margin-top: 11px;
  box-shadow: 0 11px 0 #c7c7c7, 0 44px 0 #c7c7c7, 0 66px 0 #c7c7c7, 0 88px 0 #c7c7c7, 0 99px 0 #c7c7c7, 0 110px 0 #c7c7c7, 0 121px 0 #c7c7c7, 0 143px 0 #c7c7c7, 0 154px 0 #c7c7c7, 0 165px 0 #c7c7c7, 0 176px 0 #c7c7c7, 0 187px 0 #c7c7c7, 0 198px 0 #c7c7c7, 0 209px 0 #c7c7c7, 0 220px 0 #c7c7c7, 0 231px 0 #c7c7c7, 0 242px 0 #c7c7c7, 0 253px 0 #c7c7c7, 0 264px 0 #c7c7c7;
}
.map__item:nth-child(36) {
  margin-top: 55px;
  box-shadow: 0 11px 0 #c7c7c7, 0 22px 0 #c7c7c7, 0 33px 0 #c7c7c7, 0 44px 0 #c7c7c7, 0 55px 0 #c7c7c7, 0 66px 0 #c7c7c7, 0 99px 0 #c7c7c7, 0 110px 0 #c7c7c7, 0 121px 0 #c7c7c7, 0 132px 0 #c7c7c7, 0 143px 0 #c7c7c7, 0 154px 0 #c7c7c7, 0 165px 0 #c7c7c7, 0 176px 0 #c7c7c7, 0 187px 0 #c7c7c7, 0 198px 0 #c7c7c7, 0 209px 0 #c7c7c7, 0 220px 0 #c7c7c7;
}
.map__item:nth-child(37) {
  margin-top: 55px;
  box-shadow: 0 11px 0 #c7c7c7, 0 22px 0 #c7c7c7, 0 33px 0 #c7c7c7, 0 44px 0 #c7c7c7, 0 55px 0 #c7c7c7, 0 66px 0 #c7c7c7, 0 77px 0 #c7c7c7, 0 99px 0 #c7c7c7, 0 110px 0 #c7c7c7, 0 121px 0 #c7c7c7, 0 132px 0 #c7c7c7, 0 143px 0 #c7c7c7, 0 154px 0 #c7c7c7, 0 165px 0 #c7c7c7, 0 176px 0 #c7c7c7, 0 187px 0 #c7c7c7, 0 198px 0 #c7c7c7, 0 209px 0 #c7c7c7;
}
.map__item:nth-child(38) {
  margin-top: 77px;
  box-shadow: 0 11px 0 #c7c7c7, 0 22px 0 #c7c7c7, 0 33px 0 #c7c7c7, 0 44px 0 #c7c7c7, 0 55px 0 #c7c7c7, 0 66px 0 #c7c7c7, 0 77px 0 #c7c7c7, 0 88px 0 #c7c7c7, 0 110px 0 #c7c7c7, 0 121px 0 #c7c7c7, 0 132px 0 #c7c7c7, 0 143px 0 #c7c7c7, 0 154px 0 #c7c7c7, 0 165px 0 #c7c7c7;
}
.map__item:nth-child(39) {
  margin-top: 66px;
  box-shadow: 0 11px 0 #c7c7c7, 0 22px 0 #c7c7c7, 0 33px 0 #c7c7c7, 0 44px 0 #c7c7c7, 0 55px 0 #c7c7c7, 0 66px 0 #c7c7c7, 0 77px 0 #c7c7c7, 0 88px 0 #c7c7c7, 0 99px 0 #c7c7c7, 0 110px 0 #c7c7c7, 0 121px 0 #c7c7c7, 0 132px 0 #c7c7c7, 0 143px 0 #c7c7c7, 0 187px 0 #c7c7c7, 0 198px 0 #c7c7c7;
}
.map__item:nth-child(40) {
  margin-top: 66px;
  box-shadow: 0 11px 0 #c7c7c7, 0 22px 0 #c7c7c7, 0 33px 0 #c7c7c7, 0 44px 0 #c7c7c7, 0 77px 0 #c7c7c7, 0 88px 0 #c7c7c7, 0 99px 0 #c7c7c7, 0 110px 0 #c7c7c7, 0 132px 0 #c7c7c7;
}
.map__item:nth-child(41) {
  margin-top: 33px;
  box-shadow: 0 33px 0 #c7c7c7, 0 44px 0 #c7c7c7, 0 55px 0 #c7c7c7, 0 66px 0 #c7c7c7, 0 77px 0 #c7c7c7, 0 88px 0 #c7c7c7, 0 99px 0 #c7c7c7, 0 110px 0 #c7c7c7, 0 121px 0 #c7c7c7, 0 132px 0 #c7c7c7, 0 143px 0 #c7c7c7;
}
.map__item:nth-child(42) {
  margin-top: 55px;
  box-shadow: 0 11px 0 #c7c7c7, 0 22px 0 #c7c7c7, 0 33px 0 #c7c7c7, 0 44px 0 #c7c7c7, 0 55px 0 #c7c7c7, 0 66px 0 #c7c7c7, 0 77px 0 #c7c7c7, 0 88px 0 #c7c7c7, 0 99px 0 #c7c7c7, 0 110px 0 #c7c7c7;
}
.map__item:nth-child(43) {
  margin-top: 66px;
  box-shadow: 0 11px 0 #c7c7c7, 0 22px 0 #c7c7c7, 0 33px 0 #c7c7c7, 0 44px 0 #c7c7c7, 0 55px 0 #c7c7c7, 0 66px 0 #c7c7c7, 0 77px 0 #c7c7c7, 0 88px 0 #c7c7c7, 0 99px 0 #c7c7c7;
}
.map__item:nth-child(44) {
  margin-top: 55px;
  box-shadow: 0 11px 0 #c7c7c7, 0 22px 0 #c7c7c7, 0 33px 0 #c7c7c7, 0 44px 0 #c7c7c7, 0 55px 0 #c7c7c7, 0 66px 0 #c7c7c7, 0 77px 0 #c7c7c7, 0 88px 0 #c7c7c7, 0 99px 0 #c7c7c7, 0 110px 0 #c7c7c7, 0 121px 0 #c7c7c7;
}
.map__item:nth-child(45) {
  margin-top: 44px;
  box-shadow: 0 11px 0 #c7c7c7, 0 22px 0 #c7c7c7, 0 33px 0 #c7c7c7, 0 44px 0 #c7c7c7, 0 55px 0 #c7c7c7, 0 66px 0 #c7c7c7, 0 77px 0 #c7c7c7, 0 88px 0 #c7c7c7, 0 99px 0 #c7c7c7, 0 110px 0 #c7c7c7, 0 121px 0 #c7c7c7, 0 132px 0 #c7c7c7, 0 143px 0 #c7c7c7, 0 154px 0 #c7c7c7;
}
.map__item:nth-child(46) {
  margin-top: 44px;
  box-shadow: 0 11px 0 #c7c7c7, 0 22px 0 #c7c7c7, 0 33px 0 #c7c7c7, 0 44px 0 #c7c7c7, 0 55px 0 #c7c7c7, 0 66px 0 #c7c7c7, 0 77px 0 #c7c7c7, 0 88px 0 #c7c7c7, 0 99px 0 #c7c7c7, 0 110px 0 #c7c7c7, 0 121px 0 #c7c7c7, 0 132px 0 #c7c7c7;
}
.map__item:nth-child(47) {
  margin-top: 33px;
  box-shadow: 0 11px 0 #c7c7c7, 0 22px 0 #c7c7c7, 0 33px 0 #c7c7c7, 0 44px 0 #c7c7c7, 0 55px 0 #c7c7c7, 0 66px 0 #c7c7c7, 0 77px 0 #c7c7c7, 0 88px 0 #c7c7c7, 0 99px 0 #c7c7c7, 0 110px 0 #c7c7c7, 0 121px 0 #c7c7c7, 0 132px 0 #c7c7c7;
}
.map__item:nth-child(48) {
  margin-top: 11px;
  box-shadow: 0 22px 0 #c7c7c7, 0 33px 0 #c7c7c7, 0 44px 0 #c7c7c7, 0 55px 0 #c7c7c7, 0 66px 0 #c7c7c7, 0 77px 0 #c7c7c7, 0 88px 0 #c7c7c7, 0 99px 0 #c7c7c7, 0 110px 0 #c7c7c7, 0 121px 0 #c7c7c7, 0 132px 0 #c7c7c7, 0 143px 0 #c7c7c7, 0 154px 0 #c7c7c7, 0 165px 0 #c7c7c7;
}
.map__item:nth-child(49) {
  margin-top: 33px;
  box-shadow: 0 11px 0 #c7c7c7, 0 22px 0 #c7c7c7, 0 33px 0 #c7c7c7, 0 44px 0 #c7c7c7, 0 55px 0 #c7c7c7, 0 66px 0 #c7c7c7, 0 77px 0 #c7c7c7, 0 88px 0 #c7c7c7, 0 99px 0 #c7c7c7, 0 110px 0 #c7c7c7, 0 121px 0 #c7c7c7, 0 132px 0 #c7c7c7, 0 143px 0 #c7c7c7, 0 154px 0 #c7c7c7, 0 176px 0 #c7c7c7, 0 187px 0 #c7c7c7;
}
.map__item:nth-child(50) {
  margin-top: 33px;
  box-shadow: 0 11px 0 #c7c7c7, 0 22px 0 #c7c7c7, 0 33px 0 #c7c7c7, 0 44px 0 #c7c7c7, 0 55px 0 #c7c7c7, 0 66px 0 #c7c7c7, 0 77px 0 #c7c7c7, 0 88px 0 #c7c7c7, 0 99px 0 #c7c7c7, 0 110px 0 #c7c7c7, 0 121px 0 #c7c7c7, 0 132px 0 #c7c7c7, 0 154px 0 #c7c7c7;
}
.map__item:nth-child(51) {
  margin-top: 44px;
  box-shadow: 0 11px 0 #c7c7c7, 0 22px 0 #c7c7c7, 0 33px 0 #c7c7c7, 0 44px 0 #c7c7c7, 0 55px 0 #c7c7c7, 0 66px 0 #c7c7c7, 0 77px 0 #c7c7c7, 0 88px 0 #c7c7c7, 0 99px 0 #c7c7c7, 0 110px 0 #c7c7c7, 0 121px 0 #c7c7c7, 0 176px 0 #c7c7c7, 0 187px 0 #c7c7c7;
}
.map__item:nth-child(52) {
  margin-top: 44px;
  box-shadow: 0 11px 0 #c7c7c7, 0 22px 0 #c7c7c7, 0 33px 0 #c7c7c7, 0 44px 0 #c7c7c7, 0 55px 0 #c7c7c7, 0 66px 0 #c7c7c7, 0 77px 0 #c7c7c7, 0 88px 0 #c7c7c7, 0 99px 0 #c7c7c7, 0 110px 0 #c7c7c7, 0 121px 0 #c7c7c7, 0 220px 0 #c7c7c7, 0 231px 0 #c7c7c7, 0 242px 0 #c7c7c7;
}
.map__item:nth-child(53) {
  margin-top: 44px;
  box-shadow: 0 11px 0 #c7c7c7, 0 22px 0 #c7c7c7, 0 33px 0 #c7c7c7, 0 44px 0 #c7c7c7, 0 55px 0 #c7c7c7, 0 66px 0 #c7c7c7, 0 77px 0 #c7c7c7, 0 88px 0 #c7c7c7, 0 209px 0 #c7c7c7, 0 220px 0 #c7c7c7, 0 231px 0 #c7c7c7;
}
.map__item:nth-child(54) {
  margin-top: 55px;
  box-shadow: 0 11px 0 #c7c7c7, 0 22px 0 #c7c7c7, 0 33px 0 #c7c7c7, 0 44px 0 #c7c7c7, 0 55px 0 #c7c7c7, 0 66px 0 #c7c7c7, 0 77px 0 #c7c7c7, 0 187px 0 #c7c7c7, 0 198px 0 #c7c7c7, 0 209px 0 #c7c7c7, 0 220px 0 #c7c7c7;
}
.map__item:nth-child(55) {
  margin-top: 55px;
  box-shadow: 0 11px 0 #c7c7c7, 0 22px 0 #c7c7c7, 0 33px 0 #c7c7c7, 0 44px 0 #c7c7c7, 0 55px 0 #c7c7c7, 0 66px 0 #c7c7c7, 0 187px 0 #c7c7c7, 0 198px 0 #c7c7c7, 0 209px 0 #c7c7c7, 0 220px 0 #c7c7c7;
}
.map__item:nth-child(56) {
  margin-top: 33px;
  box-shadow: 0 11px 0 #c7c7c7, 0 22px 0 #c7c7c7, 0 33px 0 #c7c7c7, 0 44px 0 #c7c7c7, 0 99px 0 #c7c7c7, 0 198px 0 #c7c7c7, 0 220px 0 #c7c7c7, 0 231px 0 #c7c7c7, 0 242px 0 #c7c7c7, 0 253px 0 #c7c7c7;
}
.map__item:nth-child(57) {
  margin-top: 44px;
  box-shadow: 0 11px 0 #c7c7c7, 0 22px 0 #c7c7c7, 0 33px 0 #c7c7c7, 0 187px 0 #c7c7c7, 0 209px 0 #c7c7c7, 0 220px 0 #c7c7c7, 0 231px 0 #c7c7c7, 0 242px 0 #c7c7c7;
}
.map__item:nth-child(58) {
  margin-top: 55px;
  box-shadow: 0 11px 0 #c7c7c7, 0 22px 0 #c7c7c7, 0 209px 0 #c7c7c7, 0 220px 0 #c7c7c7;
}
.map__item:nth-child(59) {
  margin-top: 55px;
  box-shadow: 0 11px 0 #c7c7c7, 0 22px 0 #c7c7c7, 0 33px 0 #c7c7c7, 0 44px 0 #c7c7c7;
}
.map__item:nth-child(60) {
  margin-top: 55px;
  box-shadow: 0 11px 0 #c7c7c7;
}
.map__item:nth-child(61) {
  margin-top: 55px;
  box-shadow: 0 11px 0 #c7c7c7, 0 22px 0 #c7c7c7, 0 253px 0 #c7c7c7;
}
.map__item:nth-child(62) {
  margin-top: 55px;
  box-shadow: 0 11px 0 #c7c7c7, 0 22px 0 #c7c7c7, 0 242px 0 #c7c7c7;
}
.map__item:nth-child(63) {
  margin-top: 55px;
  box-shadow: 0 11px 0 #c7c7c7;
}
.map__item:nth-child(64) {
  margin-top: 66px;
}

@media screen and (min-width: 960px) {
  .map__marker:hover .map__marker-content, .map__marker:focus .map__marker-content {
    opacity: 1;
    visibility: visible;
    z-index: 30;
    transform: translateX(-50%) translateY(-50%) scale(1);
  }
  .map__marker-content {
    top: 50%;
    left: 50%;
  }
  .map__marker-content.mobile {
    display: none;
  }
}
/*--------------------------------------------------------------
# Map Section
--------------------------------------------------------------*/
.map-section {
  width: 100%;
  padding: 60px 0;
}
.map-section__title {
  padding-left: 50px;
  color: #000;
  margin-bottom: 60px;
}
.map-section__sm-title {
  margin-bottom: 40px;
  font-size: 18px;
  font-weight: 300;
  letter-spacing: 0.5px;
  line-height: 26px;
  text-align: center;
}
.map-section__sm-title span {
  font-size: 26px;
  line-height: 26px;
  font-weight: 600;
  letter-spacing: 0.19px;
  vertical-align: middle;
}
.map-section img {
  display: block;
  margin: 0 auto 70px;
}
.map-section__text {
  width: 70%;
  margin: 0 auto;
  text-align: center;
}
.map-section__text p {
  font-size: 18px;
  font-weight: 300;
  line-height: 28.8px;
}
.map-section__list {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 50%;
  margin: 0 auto;
}
.map-section__list .map-section__item * {
  line-height: 1.6;
}
.map-section__list .map-section__item-title {
  font-size: 18px;
  font-family: "Futura", sans-serif;
  font-weight: 700;
  margin: 0;
  letter-spacing: 0.5px;
  line-height: 26px;
}
.map-section__item {
  width: 37%;
  margin: 0 30px 50px;
  text-align: center;
}
.map-section__img-wrap {
  position: relative;
  text-align: center;
}

@media screen and (max-width: 600px) {
  .map-section {
    padding: 30px 0;
  }
  .map-section__title {
    padding: 0 30px;
    font-size: 36px;
    font-weight: 400;
    letter-spacing: 0.19px;
    line-height: 47px;
    text-align: center;
  }
  .map-section__text {
    width: 75%;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 0.44px;
    line-height: 25px;
  }
  .map-section__list {
    width: 100%;
  }
  .map-section__item {
    width: 100%;
  }
  .map-section__img-wrap {
    max-width: 100%;
    overflow-x: auto;
    padding: 0 5px;
  }
}
/*--------------------------------------------------------------
# Map Mobile
--------------------------------------------------------------*/
@media screen and (max-width: 960px) {
  .map {
    width: 322px;
    height: 611px;
  }
  .map__marker-content {
    width: 210px;
    height: 210px;
  }
  .map__marker-content.desktop {
    display: none;
  }
  .map__marker-content.active {
    top: 139.5px !important;
    left: 50% !important;
    transform: translateX(-50%) translateY(-50%) scale(1);
    opacity: 1;
    visibility: visible;
    z-index: 50;
  }
  .map__img-wrap {
    padding: 0 5px;
  }
  .map__body {
    flex-wrap: wrap;
  }
  .map__break {
    flex-basis: 100%;
    display: block;
  }
  .map__item {
    width: 7px;
    height: 7px;
    margin: 0 2px 0 0;
  }
  .map__item:first-child {
    margin-bottom: 277px;
  }
  .map__item:first-child, .map__item:nth-child(2), .map__item:nth-child(3), .map__item:nth-child(4), .map__item:nth-child(5) {
    margin-top: 45px;
    box-shadow: 0 9px 0 #c7c7c7, 0 18px 0 #c7c7c7;
  }
  .map__item:nth-child(6) {
    margin-top: 45px;
    box-shadow: 0 9px 0 #c7c7c7, 0 18px 0 #c7c7c7, 0 27px 0 #c7c7c7;
  }
  .map__item:nth-child(7) {
    margin-top: 45px;
    box-shadow: 0 9px 0 #c7c7c7, 0 18px 0 #c7c7c7, 0 27px 0 #c7c7c7, 0 36px 0 #c7c7c7;
  }
  .map__item:nth-child(8) {
    margin-top: 36px;
    box-shadow: 0 9px 0 #c7c7c7, 0 18px 0 #c7c7c7, 0 27px 0 #c7c7c7, 0 36px 0 #c7c7c7, 0 45px 0 #c7c7c7, 0 54px 0 #c7c7c7, 0 72px 0 #c7c7c7;
  }
  .map__item:nth-child(9) {
    margin-top: 36px;
    box-shadow: 0 9px 0 #c7c7c7, 0 18px 0 #c7c7c7, 0 27px 0 #c7c7c7, 0 36px 0 #c7c7c7, 0 45px 0 #c7c7c7, 0 54px 0 #c7c7c7, 0 63px 0 #c7c7c7, 0 72px 0 #c7c7c7, 0 81px 0 #c7c7c7;
  }
  .map__item:nth-child(10) {
    margin-top: 18px;
    box-shadow: 0 9px 0 #c7c7c7, 0 18px 0 #c7c7c7, 0 27px 0 #c7c7c7, 0 36px 0 #c7c7c7, 0 45px 0 #c7c7c7, 0 54px 0 #c7c7c7, 0 63px 0 #c7c7c7, 0 72px 0 #c7c7c7, 0 81px 0 #c7c7c7, 0 90px 0 #c7c7c7, 0 99px 0 #c7c7c7, 0 108px 0 #c7c7c7;
  }
  .map__item:nth-child(11) {
    margin-top: 27px;
    box-shadow: 0 9px 0 #c7c7c7, 0 18px 0 #c7c7c7, 0 27px 0 #c7c7c7, 0 36px 0 #c7c7c7, 0 45px 0 #c7c7c7, 0 54px 0 #c7c7c7, 0 63px 0 #c7c7c7, 0 72px 0 #c7c7c7, 0 81px 0 #c7c7c7, 0 90px 0 #c7c7c7, 0 99px 0 #c7c7c7, 0 108px 0 #c7c7c7;
  }
  .map__item:nth-child(12) {
    margin-top: 54px;
    box-shadow: 0 9px 0 #c7c7c7, 0 18px 0 #c7c7c7, 0 27px 0 #c7c7c7, 0 36px 0 #c7c7c7, 0 45px 0 #c7c7c7, 0 54px 0 #c7c7c7, 0 63px 0 #c7c7c7, 0 72px 0 #c7c7c7, 0 81px 0 #c7c7c7, 0 90px 0 #c7c7c7;
  }
  .map__item:nth-child(13) {
    margin-top: 9px;
    box-shadow: 0 9px 0 #c7c7c7, 0 18px 0 #c7c7c7, 0 36px 0 #c7c7c7, 0 45px 0 #c7c7c7, 0 54px 0 #c7c7c7, 0 63px 0 #c7c7c7, 0 72px 0 #c7c7c7, 0 81px 0 #c7c7c7, 0 90px 0 #c7c7c7, 0 99px 0 #c7c7c7, 0 108px 0 #c7c7c7, 0 117px 0 #c7c7c7;
  }
  .map__item:nth-child(14) {
    margin-top: 9px;
    box-shadow: 0 18px 0 #c7c7c7, 0 36px 0 #c7c7c7, 0 45px 0 #c7c7c7, 0 72px 0 #c7c7c7, 0 81px 0 #c7c7c7, 0 90px 0 #c7c7c7, 0 99px 0 #c7c7c7, 0 108px 0 #c7c7c7, 0 117px 0 #c7c7c7, 0 135px 0 #c7c7c7, 0 144px 0 #c7c7c7;
  }
  .map__item:nth-child(15) {
    box-shadow: 0 9px 0 #c7c7c7, 0 18px 0 #c7c7c7, 0 27px 0 #c7c7c7, 0 36px 0 #c7c7c7, 0 45px 0 #c7c7c7, 0 54px 0 #c7c7c7, 0 81px 0 #c7c7c7, 0 90px 0 #c7c7c7, 0 99px 0 #c7c7c7, 0 108px 0 #c7c7c7, 0 117px 0 #c7c7c7, 0 126px 0 #c7c7c7, 0 153px 0 #c7c7c7;
  }
  .map__item:nth-child(16) {
    box-shadow: 0 9px 0 #c7c7c7, 0 18px 0 #c7c7c7, 0 36px 0 #c7c7c7, 0 63px 0 #c7c7c7, 0 81px 0 #c7c7c7, 0 90px 0 #c7c7c7, 0 99px 0 #c7c7c7, 0 108px 0 #c7c7c7, 0 117px 0 #c7c7c7, 0 162px 0 #c7c7c7, 0 180px 0 #c7c7c7, 0 189px 0 #c7c7c7;
  }
  .map__item:nth-child(17) {
    box-shadow: 0 9px 0 #c7c7c7, 0 45px 0 #c7c7c7, 0 54px 0 #c7c7c7, 0 63px 0 #c7c7c7, 0 72px 0 #c7c7c7, 0 81px 0 #c7c7c7, 0 90px 0 #c7c7c7, 0 99px 0 #c7c7c7, 0 108px 0 #c7c7c7, 0 162px 0 #c7c7c7, 0 171px 0 #c7c7c7, 0 180px 0 #c7c7c7, 0 189px 0 #c7c7c7, 0 198px 0 #c7c7c7, 0 243px 0 #c7c7c7, 0 252px 0 #c7c7c7, 0 261px 0 #c7c7c7, 0 270px 0 #c7c7c7;
  }
  .map__item:nth-child(18) {
    box-shadow: 0 9px 0 #c7c7c7, 0 18px 0 #c7c7c7, 0 54px 0 #c7c7c7, 0 72px 0 #c7c7c7, 0 81px 0 #c7c7c7, 0 90px 0 #c7c7c7, 0 99px 0 #c7c7c7, 0 162px 0 #c7c7c7, 0 171px 0 #c7c7c7, 0 180px 0 #c7c7c7, 0 189px 0 #c7c7c7, 0 198px 0 #c7c7c7, 0 207px 0 #c7c7c7, 0 216px 0 #c7c7c7, 0 225px 0 #c7c7c7, 0 234px 0 #c7c7c7, 0 243px 0 #c7c7c7, 0 252px 0 #c7c7c7, 0 261px 0 #c7c7c7, 0 270px 0 #c7c7c7;
  }
  .map__item:nth-child(19) {
    box-shadow: 0 9px 0 #c7c7c7, 0 18px 0 #c7c7c7, 0 72px 0 #c7c7c7, 0 81px 0 #c7c7c7, 0 99px 0 #c7c7c7, 0 162px 0 #c7c7c7, 0 171px 0 #c7c7c7, 0 180px 0 #c7c7c7, 0 189px 0 #c7c7c7, 0 198px 0 #c7c7c7, 0 207px 0 #c7c7c7, 0 216px 0 #c7c7c7, 0 225px 0 #c7c7c7, 0 234px 0 #c7c7c7, 0 243px 0 #c7c7c7;
  }
  .map__item:nth-child(20) {
    margin-top: 9px;
    box-shadow: 0 9px 0 #c7c7c7, 0 18px 0 #c7c7c7, 0 81px 0 #c7c7c7, 0 162px 0 #c7c7c7, 0 171px 0 #c7c7c7, 0 180px 0 #c7c7c7, 0 189px 0 #c7c7c7, 0 198px 0 #c7c7c7, 0 207px 0 #c7c7c7, 0 216px 0 #c7c7c7;
  }
  .map__item:nth-child(21) {
    margin-top: 9px;
    box-shadow: 0 9px 0 #c7c7c7, 0 18px 0 #c7c7c7, 0 27px 0 #c7c7c7, 0 36px 0 #c7c7c7, 0 45px 0 #c7c7c7, 0 162px 0 #c7c7c7, 0 171px 0 #c7c7c7, 0 180px 0 #c7c7c7, 0 189px 0 #c7c7c7, 0 198px 0 #c7c7c7, 0 207px 0 #c7c7c7, 0 216px 0 #c7c7c7;
  }
  .map__item:nth-child(22) {
    box-shadow: 0 9px 0 #c7c7c7, 0 18px 0 #c7c7c7, 0 27px 0 #c7c7c7, 0 36px 0 #c7c7c7, 0 45px 0 #c7c7c7, 0 54px 0 #c7c7c7, 0 63px 0 #c7c7c7, 0 180px 0 #c7c7c7, 0 189px 0 #c7c7c7, 0 198px 0 #c7c7c7, 0 207px 0 #c7c7c7;
  }
  .map__item:nth-child(23) {
    box-shadow: 0 9px 0 #c7c7c7, 0 18px 0 #c7c7c7, 0 27px 0 #c7c7c7, 0 36px 0 #c7c7c7, 0 45px 0 #c7c7c7, 0 54px 0 #c7c7c7, 0 189px 0 #c7c7c7, 0 198px 0 #c7c7c7, 0 207px 0 #c7c7c7;
  }
  .map__item:nth-child(24), .map__item:nth-child(25) {
    box-shadow: 0 9px 0 #c7c7c7, 0 18px 0 #c7c7c7, 0 27px 0 #c7c7c7, 0 36px 0 #c7c7c7, 0 45px 0 #c7c7c7;
  }
  .map__item:nth-child(26) {
    box-shadow: 0 9px 0 #c7c7c7, 0 18px 0 #c7c7c7, 0 27px 0 #c7c7c7, 0 54px 0 #c7c7c7;
  }
  .map__item:nth-child(27) {
    margin-top: 54px;
    box-shadow: 0 99px 0 #c7c7c7;
  }
  .map__item:nth-child(29) {
    margin-top: 135px;
    box-shadow: 0 9px 0 #c7c7c7, 0 18px 0 #c7c7c7, 0 27px 0 #c7c7c7;
  }
  .map__item:nth-child(30) {
    margin-top: 81px;
    box-shadow: 0 27px 0 #c7c7c7, 0 36px 0 #c7c7c7, 0 45px 0 #c7c7c7, 0 54px 0 #c7c7c7, 0 63px 0 #c7c7c7, 0 72px 0 #c7c7c7, 0 81px 0 #c7c7c7;
  }
  .map__item:nth-child(31) {
    margin-top: 81px;
    box-shadow: 0 18px 0 #c7c7c7, 0 27px 0 #c7c7c7, 0 36px 0 #c7c7c7, 0 45px 0 #c7c7c7, 0 54px 0 #c7c7c7, 0 63px 0 #c7c7c7, 0 72px 0 #c7c7c7, 0 81px 0 #c7c7c7;
  }
  .map__item:nth-child(32) {
    margin-top: 63px;
    box-shadow: 0 27px 0 #c7c7c7, 0 36px 0 #c7c7c7, 0 54px 0 #c7c7c7, 0 63px 0 #c7c7c7, 0 72px 0 #c7c7c7, 0 81px 0 #c7c7c7, 0 90px 0 #c7c7c7, 0 99px 0 #c7c7c7;
  }
  .map__item:nth-child(33) {
    margin-top: 63px;
    box-shadow: 0 18px 0 #c7c7c7, 0 27px 0 #c7c7c7, 0 36px 0 #c7c7c7, 0 63px 0 #c7c7c7, 0 72px 0 #c7c7c7, 0 81px 0 #c7c7c7, 0 90px 0 #c7c7c7, 0 99px 0 #c7c7c7, 0 108px 0 #c7c7c7, 0 117px 0 #c7c7c7;
  }
  .map__item:nth-child(34) {
    margin-top: 18px;
    box-shadow: 0 27px 0 #c7c7c7, 0 36px 0 #c7c7c7, 0 45px 0 #c7c7c7, 0 54px 0 #c7c7c7, 0 63px 0 #c7c7c7, 0 72px 0 #c7c7c7, 0 81px 0 #c7c7c7, 0 90px 0 #c7c7c7, 0 108px 0 #c7c7c7, 0 117px 0 #c7c7c7, 0 126px 0 #c7c7c7, 0 135px 0 #c7c7c7, 0 144px 0 #c7c7c7, 0 153px 0 #c7c7c7, 0 162px 0 #c7c7c7, 0 171px 0 #c7c7c7, 0 180px 0 #c7c7c7, 0 189px 0 #c7c7c7, 0 198px 0 #c7c7c7;
  }
  .map__item:nth-child(35) {
    margin-top: 9px;
    box-shadow: 0 9px 0 #c7c7c7, 0 36px 0 #c7c7c7, 0 54px 0 #c7c7c7, 0 72px 0 #c7c7c7, 0 81px 0 #c7c7c7, 0 90px 0 #c7c7c7, 0 99px 0 #c7c7c7, 0 117px 0 #c7c7c7, 0 126px 0 #c7c7c7, 0 135px 0 #c7c7c7, 0 144px 0 #c7c7c7, 0 153px 0 #c7c7c7, 0 162px 0 #c7c7c7, 0 171px 0 #c7c7c7, 0 180px 0 #c7c7c7, 0 189px 0 #c7c7c7, 0 198px 0 #c7c7c7, 0 207px 0 #c7c7c7, 0 216px 0 #c7c7c7;
  }
  .map__item:nth-child(36) {
    margin-top: 45px;
    box-shadow: 0 9px 0 #c7c7c7, 0 18px 0 #c7c7c7, 0 27px 0 #c7c7c7, 0 36px 0 #c7c7c7, 0 45px 0 #c7c7c7, 0 54px 0 #c7c7c7, 0 81px 0 #c7c7c7, 0 90px 0 #c7c7c7, 0 99px 0 #c7c7c7, 0 108px 0 #c7c7c7, 0 117px 0 #c7c7c7, 0 126px 0 #c7c7c7, 0 135px 0 #c7c7c7, 0 144px 0 #c7c7c7, 0 153px 0 #c7c7c7, 0 162px 0 #c7c7c7, 0 171px 0 #c7c7c7, 0 180px 0 #c7c7c7;
  }
  .map__item:nth-child(37) {
    margin-top: 45px;
    box-shadow: 0 9px 0 #c7c7c7, 0 18px 0 #c7c7c7, 0 27px 0 #c7c7c7, 0 36px 0 #c7c7c7, 0 45px 0 #c7c7c7, 0 54px 0 #c7c7c7, 0 63px 0 #c7c7c7, 0 81px 0 #c7c7c7, 0 90px 0 #c7c7c7, 0 99px 0 #c7c7c7, 0 108px 0 #c7c7c7, 0 117px 0 #c7c7c7, 0 126px 0 #c7c7c7, 0 135px 0 #c7c7c7, 0 144px 0 #c7c7c7, 0 153px 0 #c7c7c7, 0 162px 0 #c7c7c7, 0 171px 0 #c7c7c7;
  }
  .map__item:nth-child(38) {
    margin-top: 63px;
    box-shadow: 0 9px 0 #c7c7c7, 0 18px 0 #c7c7c7, 0 27px 0 #c7c7c7, 0 36px 0 #c7c7c7, 0 45px 0 #c7c7c7, 0 54px 0 #c7c7c7, 0 63px 0 #c7c7c7, 0 72px 0 #c7c7c7, 0 90px 0 #c7c7c7, 0 99px 0 #c7c7c7, 0 108px 0 #c7c7c7, 0 117px 0 #c7c7c7, 0 126px 0 #c7c7c7, 0 135px 0 #c7c7c7;
  }
  .map__item:nth-child(39) {
    margin-top: 54px;
    box-shadow: 0 9px 0 #c7c7c7, 0 18px 0 #c7c7c7, 0 27px 0 #c7c7c7, 0 36px 0 #c7c7c7, 0 45px 0 #c7c7c7, 0 54px 0 #c7c7c7, 0 63px 0 #c7c7c7, 0 72px 0 #c7c7c7, 0 81px 0 #c7c7c7, 0 90px 0 #c7c7c7, 0 99px 0 #c7c7c7, 0 108px 0 #c7c7c7, 0 117px 0 #c7c7c7, 0 153px 0 #c7c7c7, 0 162px 0 #c7c7c7;
  }
  .map__item:nth-child(40) {
    margin-top: 54px;
    box-shadow: 0 9px 0 #c7c7c7, 0 18px 0 #c7c7c7, 0 27px 0 #c7c7c7, 0 36px 0 #c7c7c7, 0 63px 0 #c7c7c7, 0 72px 0 #c7c7c7, 0 81px 0 #c7c7c7, 0 90px 0 #c7c7c7, 0 108px 0 #c7c7c7;
  }
  .map__item:nth-child(41) {
    margin-top: 27px;
    box-shadow: 0 27px 0 #c7c7c7, 0 36px 0 #c7c7c7, 0 45px 0 #c7c7c7, 0 54px 0 #c7c7c7, 0 63px 0 #c7c7c7, 0 72px 0 #c7c7c7, 0 81px 0 #c7c7c7, 0 90px 0 #c7c7c7, 0 99px 0 #c7c7c7, 0 108px 0 #c7c7c7, 0 117px 0 #c7c7c7;
  }
  .map__item:nth-child(42) {
    margin-top: 45px;
    box-shadow: 0 9px 0 #c7c7c7, 0 18px 0 #c7c7c7, 0 27px 0 #c7c7c7, 0 36px 0 #c7c7c7, 0 45px 0 #c7c7c7, 0 54px 0 #c7c7c7, 0 63px 0 #c7c7c7, 0 72px 0 #c7c7c7, 0 81px 0 #c7c7c7, 0 90px 0 #c7c7c7;
  }
  .map__item:nth-child(43) {
    margin-top: 54px;
    box-shadow: 0 9px 0 #c7c7c7, 0 18px 0 #c7c7c7, 0 27px 0 #c7c7c7, 0 36px 0 #c7c7c7, 0 45px 0 #c7c7c7, 0 54px 0 #c7c7c7, 0 63px 0 #c7c7c7, 0 72px 0 #c7c7c7, 0 81px 0 #c7c7c7;
  }
  .map__item:nth-child(44) {
    margin-top: 45px;
    box-shadow: 0 9px 0 #c7c7c7, 0 18px 0 #c7c7c7, 0 27px 0 #c7c7c7, 0 36px 0 #c7c7c7, 0 45px 0 #c7c7c7, 0 54px 0 #c7c7c7, 0 63px 0 #c7c7c7, 0 72px 0 #c7c7c7, 0 81px 0 #c7c7c7, 0 90px 0 #c7c7c7, 0 99px 0 #c7c7c7;
  }
  .map__item:nth-child(45) {
    margin-top: 36px;
    box-shadow: 0 9px 0 #c7c7c7, 0 18px 0 #c7c7c7, 0 27px 0 #c7c7c7, 0 36px 0 #c7c7c7, 0 45px 0 #c7c7c7, 0 54px 0 #c7c7c7, 0 63px 0 #c7c7c7, 0 72px 0 #c7c7c7, 0 81px 0 #c7c7c7, 0 90px 0 #c7c7c7, 0 99px 0 #c7c7c7, 0 108px 0 #c7c7c7, 0 117px 0 #c7c7c7, 0 126px 0 #c7c7c7;
  }
  .map__item:nth-child(46) {
    margin-top: 36px;
    box-shadow: 0 9px 0 #c7c7c7, 0 18px 0 #c7c7c7, 0 27px 0 #c7c7c7, 0 36px 0 #c7c7c7, 0 45px 0 #c7c7c7, 0 54px 0 #c7c7c7, 0 63px 0 #c7c7c7, 0 72px 0 #c7c7c7, 0 81px 0 #c7c7c7, 0 90px 0 #c7c7c7, 0 99px 0 #c7c7c7, 0 108px 0 #c7c7c7;
  }
  .map__item:nth-child(47) {
    margin-top: 27px;
    box-shadow: 0 9px 0 #c7c7c7, 0 18px 0 #c7c7c7, 0 27px 0 #c7c7c7, 0 36px 0 #c7c7c7, 0 45px 0 #c7c7c7, 0 54px 0 #c7c7c7, 0 63px 0 #c7c7c7, 0 72px 0 #c7c7c7, 0 81px 0 #c7c7c7, 0 90px 0 #c7c7c7, 0 99px 0 #c7c7c7, 0 108px 0 #c7c7c7;
  }
  .map__item:nth-child(48) {
    margin-top: 9px;
    box-shadow: 0 18px 0 #c7c7c7, 0 27px 0 #c7c7c7, 0 36px 0 #c7c7c7, 0 45px 0 #c7c7c7, 0 54px 0 #c7c7c7, 0 63px 0 #c7c7c7, 0 72px 0 #c7c7c7, 0 81px 0 #c7c7c7, 0 90px 0 #c7c7c7, 0 99px 0 #c7c7c7, 0 108px 0 #c7c7c7, 0 117px 0 #c7c7c7, 0 126px 0 #c7c7c7, 0 135px 0 #c7c7c7;
  }
  .map__item:nth-child(49) {
    margin-top: 27px;
    box-shadow: 0 9px 0 #c7c7c7, 0 18px 0 #c7c7c7, 0 27px 0 #c7c7c7, 0 36px 0 #c7c7c7, 0 45px 0 #c7c7c7, 0 54px 0 #c7c7c7, 0 63px 0 #c7c7c7, 0 72px 0 #c7c7c7, 0 81px 0 #c7c7c7, 0 90px 0 #c7c7c7, 0 99px 0 #c7c7c7, 0 108px 0 #c7c7c7, 0 117px 0 #c7c7c7, 0 126px 0 #c7c7c7, 0 144px 0 #c7c7c7, 0 153px 0 #c7c7c7;
  }
  .map__item:nth-child(50) {
    margin-top: 27px;
    box-shadow: 0 9px 0 #c7c7c7, 0 18px 0 #c7c7c7, 0 27px 0 #c7c7c7, 0 36px 0 #c7c7c7, 0 45px 0 #c7c7c7, 0 54px 0 #c7c7c7, 0 63px 0 #c7c7c7, 0 72px 0 #c7c7c7, 0 81px 0 #c7c7c7, 0 90px 0 #c7c7c7, 0 99px 0 #c7c7c7, 0 108px 0 #c7c7c7, 0 126px 0 #c7c7c7;
  }
  .map__item:nth-child(51) {
    margin-top: 36px;
    box-shadow: 0 9px 0 #c7c7c7, 0 18px 0 #c7c7c7, 0 27px 0 #c7c7c7, 0 36px 0 #c7c7c7, 0 45px 0 #c7c7c7, 0 54px 0 #c7c7c7, 0 63px 0 #c7c7c7, 0 72px 0 #c7c7c7, 0 81px 0 #c7c7c7, 0 90px 0 #c7c7c7, 0 99px 0 #c7c7c7, 0 144px 0 #c7c7c7, 0 153px 0 #c7c7c7;
  }
  .map__item:nth-child(52) {
    margin-top: 36px;
    box-shadow: 0 9px 0 #c7c7c7, 0 18px 0 #c7c7c7, 0 27px 0 #c7c7c7, 0 36px 0 #c7c7c7, 0 45px 0 #c7c7c7, 0 54px 0 #c7c7c7, 0 63px 0 #c7c7c7, 0 72px 0 #c7c7c7, 0 81px 0 #c7c7c7, 0 90px 0 #c7c7c7, 0 99px 0 #c7c7c7, 0 180px 0 #c7c7c7, 0 189px 0 #c7c7c7, 0 198px 0 #c7c7c7;
  }
  .map__item:nth-child(53) {
    margin-top: 36px;
    box-shadow: 0 9px 0 #c7c7c7, 0 18px 0 #c7c7c7, 0 27px 0 #c7c7c7, 0 36px 0 #c7c7c7, 0 45px 0 #c7c7c7, 0 54px 0 #c7c7c7, 0 63px 0 #c7c7c7, 0 72px 0 #c7c7c7, 0 171px 0 #c7c7c7, 0 180px 0 #c7c7c7, 0 189px 0 #c7c7c7;
  }
  .map__item:nth-child(54) {
    margin-top: 45px;
    box-shadow: 0 9px 0 #c7c7c7, 0 18px 0 #c7c7c7, 0 27px 0 #c7c7c7, 0 36px 0 #c7c7c7, 0 45px 0 #c7c7c7, 0 54px 0 #c7c7c7, 0 63px 0 #c7c7c7, 0 153px 0 #c7c7c7, 0 162px 0 #c7c7c7, 0 171px 0 #c7c7c7, 0 180px 0 #c7c7c7;
  }
  .map__item:nth-child(55) {
    margin-top: 45px;
    box-shadow: 0 9px 0 #c7c7c7, 0 18px 0 #c7c7c7, 0 27px 0 #c7c7c7, 0 36px 0 #c7c7c7, 0 45px 0 #c7c7c7, 0 54px 0 #c7c7c7, 0 153px 0 #c7c7c7, 0 162px 0 #c7c7c7, 0 171px 0 #c7c7c7, 0 180px 0 #c7c7c7;
  }
  .map__item:nth-child(56) {
    margin-top: 27px;
    box-shadow: 0 9px 0 #c7c7c7, 0 18px 0 #c7c7c7, 0 27px 0 #c7c7c7, 0 36px 0 #c7c7c7, 0 81px 0 #c7c7c7, 0 162px 0 #c7c7c7, 0 180px 0 #c7c7c7, 0 189px 0 #c7c7c7, 0 198px 0 #c7c7c7, 0 207px 0 #c7c7c7;
  }
  .map__item:nth-child(57) {
    margin-top: 36px;
    box-shadow: 0 9px 0 #c7c7c7, 0 18px 0 #c7c7c7, 0 27px 0 #c7c7c7, 0 153px 0 #c7c7c7, 0 171px 0 #c7c7c7, 0 180px 0 #c7c7c7, 0 189px 0 #c7c7c7, 0 198px 0 #c7c7c7;
  }
  .map__item:nth-child(58) {
    margin-top: 45px;
    box-shadow: 0 9px 0 #c7c7c7, 0 18px 0 #c7c7c7, 0 171px 0 #c7c7c7, 0 180px 0 #c7c7c7;
  }
  .map__item:nth-child(59) {
    margin-top: 45px;
    box-shadow: 0 9px 0 #c7c7c7, 0 18px 0 #c7c7c7, 0 27px 0 #c7c7c7, 0 36px 0 #c7c7c7;
  }
  .map__item:nth-child(60) {
    margin-top: 45px;
    box-shadow: 0 9px 0 #c7c7c7;
  }
  .map__item:nth-child(61) {
    margin-top: 45px;
    box-shadow: 0 9px 0 #c7c7c7, 0 18px 0 #c7c7c7, 0 207px 0 #c7c7c7;
  }
  .map__item:nth-child(62) {
    margin-top: 45px;
    box-shadow: 0 9px 0 #c7c7c7, 0 18px 0 #c7c7c7, 0 198px 0 #c7c7c7;
  }
  .map__item:nth-child(63) {
    margin-top: 45px;
    box-shadow: 0 9px 0 #c7c7c7;
  }
  .map__item:nth-child(64) {
    margin-top: 54px;
  }
}
/*--------------------------------------------------------------
# Navigation
--------------------------------------------------------------*/
/*--------------------------------------------------------------
## Links
--------------------------------------------------------------*/
.brands-list {
  column-count: 3;
  height: 130px;
  column-fill: auto;
  column-width: 170px;
}

a {
  color: $app-white;
  font-size: 15px !important;
  font-weight: 400;
  text-decoration: none !important;
  font-family: 'Futura';
  line-height: 24px !important;
}

a:visited {
  color: #333;
}

a:hover,
a:focus,
a:active {
  color: $accent-color;
}

a:focus {
  outline: thin dotted;
}

a:hover,
a:active {
  outline: 0;
}

.no-link {
  > a {
    cursor: inherit;
  }
}

/*--------------------------------------------------------------
## Menus
--------------------------------------------------------------*/
.primary-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 400px;
  max-width: 100%;
  margin: 0 auto;
  list-style: none;
  padding-top: 20px;
  &__item {
    > a {
      display: block;
      padding: 10px;
      color: $app-white;
      font-size: 15px !important;
      font-weight: 400;
      font-family: 'Futura';
      line-height: 24px !important;
      cursor: pointer;
    }

    &:hover {
      > a {
        text-decoration: underline !important;
      }
    }
  }

  &__mega-menu {
    position: absolute;
    top: calc(100% + 1px);
    left: 0;
    z-index: 9999;
    width: 100%;
    max-height: 0;
    background-color: $app-black;
    overflow: hidden;

    &.active {
      max-height: 176px;
      height: 176px;
    }
  }

  &__sub-menu {
    width: 600px;
    padding: 30px 10px 40px;
    margin: 0 auto;
    color: $app-white;
    list-style: none;
    text-align: left;
  }

  &__sub-item {
    display: inline-block;
    vertical-align: top;
  }

  &__column {
    margin: 0;
    list-style: none;
  }

  &__column-item {
    &.nosocial {
      margin-bottom: 10px;
    }

    > a {
      display: block;
      padding: 5px 0;
      color: $app-white;
      font-size: 15px;
    }

    &:hover {
      > a {
        text-decoration: underline !important;
        cursor: pointer;
      }
    }
  }

  &__item.social {
    p {
      margin-bottom: 15px;
    }

    .social {
      padding: 0;
      margin: 0;
      list-style: none;

      li {
        display: inline-block;
        margin-right: 15px;

        a {
          color: $app-white;

          &:hover {
            opacity: 0.5;
          }

          &:focus {
            outline: 1px dotted;
          }
        }
      }
    }
  }
}

.primary-menu {
  &__item.has-mega-menu.hover &__mega-menu,
  &__item.has-mega-menu:hover &__mega-menu,
  &__item.social.hover &__mega-menu,
  &__item.social:hover &__mega-menu {
    max-height: 176px;
    height: 176px;
  }
}

@include respond-to(XSmall) {
  .primary-menu {
    flex-direction: column;
    align-items: flex-start;
    padding: 5px 35px;
    text-align: left;

    &__mega-menu {
      position: static;
      max-height: none;
      background: none;
    }

    &__item {
      margin-bottom: 20px;

      > a {
        padding: 5px 10px;
      }

      &.search {
        .primary-menu__mega-menu {
          display: none;

          &.active {
            position: fixed;
            top: 95;
            left: 0;
            right: 0;
            bottom: 0;
            display: block;
            width: 100%;
            padding: 50px 45px 0;
            background-color: $app-black;

            p {
              display: block;
            }
          }
        }

        h4 {
          text-align: left;
        }

        ul {
          flex-direction: column;
          align-items: flex-start;
          padding: 0 0 0 20px;

          a {
            &:hover {
              text-decoration: underline;
            }
          }

          li:not(:last-child) {
            &::after {
              display: none;
            }
          }
        }
      }

      &.social {
        .social {
          padding-left: 10px;
          margin-top: 50px;

          li {
            a {
              font-size: 26px;
            }
          }
        }
      }
    }

    &__mega-menu {
      &.active {
        max-height: none;
        height: auto;
      }
    }

    &__sub-menu {
      width: auto;
      padding: 0 0 0 20px;
    }

    &__sub-item {
      display: block;

      > p {
        display: none;
      }
    }
    &__column {
      padding-left: 0px;
      margin: 0;
      list-style: none;
    }

    &__column-item {
      > a {
        padding: 5px 10px;
        font-size: 14px !important;
        font-weight: 10;
      }
    }
  }
}

@use '@angular/material' as mat;
@import 'mat-palette-generator.scss';

$primary-color: #000;
$accent-color: #e927c1;

$primary-color-base: 500;
$primary-color-light: 300;
$primary-color-dark: 900;
$primary-color-medium: 600;
$app-theme-primary: mat.define-palette(
  createPalette($primary-color),
  $primary-color-base,
  $primary-color-light,
  $primary-color-dark
);

$accent-color-base: 500;
$accent-color-light: 300;
$accent-color-dark: 900;
$app-theme-accent: mat.define-palette(
  createPalette($accent-color),
  $accent-color-base,
  $accent-color-light,
  $accent-color-dark
);

$warn-color-base: 500;
$warn-color-light: 300;
$warn-color-dark: 900;
$app-theme-warn: mat.define-palette(mat.$red-palette, $warn-color-base, $warn-color-light, $warn-color-dark);

$highlight-color-base: 700;
$highlight-color-light: 300;
$highlight-color-dark: 900;
$app-theme-highlight: mat.define-palette(
  mat.$green-palette,
  $highlight-color-base,
  $highlight-color-light,
  $highlight-color-dark
);

// create theme (use mat-dark-theme for themes with dark backgrounds)
$app-theme: mat.define-light-theme($app-theme-primary, $app-theme-accent, $app-theme-warn);
//colors options default lighter darker default-contrast lighter-contrast darker-contrast

$app-primary-base: mat.get-color-from-palette($app-theme-primary, $primary-color-base);
$app-primary-light: mat.get-color-from-palette($app-theme-primary, $primary-color-light);
$app-primary-dark: mat.get-color-from-palette($app-theme-primary, $primary-color-dark);
$app-primary-medium: mat.get-color-from-palette($app-theme-primary, $primary-color-medium);

$app-accent-base: mat.get-color-from-palette($app-theme-accent, $accent-color-base);
$app-accent-light: mat.get-color-from-palette($app-theme-accent, $accent-color-light);
$app-accent-dark: mat.get-color-from-palette($app-theme-accent, $accent-color-dark);

$app-warn-base: mat.get-color-from-palette($app-theme-warn, $warn-color-base);
$app-warn-light: mat.get-color-from-palette($app-theme-warn, $warn-color-light);
$app-warn-dark: mat.get-color-from-palette($app-theme-warn, $warn-color-dark);

$app-highlight-base: mat.get-color-from-palette($app-theme-highlight, $highlight-color-base);

$app-white: rgb(255, 255, 255);
$app-black: rgb(0, 0, 0);

@import 'src/apps/web/styles/tools/tools';

@font-face {
  font-family: 'Ivy Mode';
  src: url(/assets/fonts/IvyMode.woff2);
}
@font-face {
  font-family: 'Futura';
  src: url(/assets/fonts/Futura.ttf);
}
@font-face {
  font-family: 'Futura';
  src: url(/assets/fonts/Futura-bold.ttf);
  font-weight: bold;
}

@mixin heading-font() {
  font-family: 'Ivy Mode';
  font-weight: 400;
}

.mat-typography h1 {
  @include heading-font();
  @include font-size(36px);
  @include line-height(25px);
  @include respond-to(Small-and-down) {
    @include font-size(36px);
    @include line-height(25px);
  }
}

.mat-typography h2 {
  @include heading-font();
  @include font-size(30px);
  @include line-height(22px);
  @include respond-to(Small-and-down) {
    @include font-size(30px);
    @include line-height(22px);
  }
}

.mat-typography h3 {
  font-family: futura;
  font-weight: 600;
  @include font-size(18px);
  @include line-height(20px);
  @include respond-to(Small-and-down) {
    @include font-size(18px);
    @include line-height(20px);
  }
}

.mat-typography h4 {
  @include heading-font();
  @include font-size(36px);
  @include line-height(43px);
  @include respond-to(Small-and-down) {
    @include font-size(28px);
    @include line-height(33px);
  }
}

.mat-typography h5 {
  @include heading-font();
  @include font-size(11px);
  @include line-height(15px);
  @include respond-to(Small-and-down) {
    @include font-size(11px);
    @include line-height(15px);
  }
}

.mat-typography h6 {
  font-family: futura;
  font-weight: 700;
  @include font-size(15px);
  @include line-height(18px);
  @include respond-to(Small-and-down) {
    @include font-size(15px);
    @include line-height(18px);
  }
}

div,
p,
span {
  font-family: 'Futura';
  font-weight: 400;
  @include font-size(16px);
  @include line-height(28px);
}

/*!
 * Font Awesome Pro 5.10.1 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */

@font-face {
  font-family: 'Font Awesome 5 Brands';
  font-style: normal;
  font-weight: 400;
  src: url(/assets/fonts/fa-brands-400.woff2) format('woff2');
}

.fab {
  font-family: 'Font Awesome 5 Brands';
}

/*--------------------------------------------------------------
# Two Sliders Section
--------------------------------------------------------------*/
.two-sliders {
  width: 100%;
  padding: 70px 0;
  margin-bottom: -410px;
  background-image: url(/assets/img/home/background-two-sliders.jpg);
  background-repeat: no-repeat;
  background-position: right -7px top 20px;
  overflow: hidden;
  &__first-slider {
    position: relative;
    height: 240px;
    margin-bottom: 10px;
  }
  &__second-slider {
    position: relative;
    height: 240px;
  }
  &__content {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: flex-start;
    min-width: 100%;
    height: 100%;
    transform: translateX(0);
    animation: slide 50s linear infinite;
    mks-glitter-image {
      display: inline-block;
      margin-right: 10px;
    }
  }
  &__second-content {
    position: absolute;
    top: 0;
    left: 100%;
    display: flex;
    justify-content: flex-start;
    min-width: 100%;
    height: 100%;
    transform: translateX(0);
    animation: slide 50s linear infinite;
    mks-glitter-image {
      display: inline-block;
      margin-right: 10px;
    }
  }
  &__first-slider &__second-content {
    left: -100%;
  }
  &__second-slider &__content {
    justify-content: flex-end;
    animation: slide-reverse 50s linear infinite;
    mks-glitter-image {
      margin-left: 10px;
      margin-right: 0;
    }
  }
  &__second-slider &__second-content {
    justify-content: flex-end;
    animation: slide-reverse 50s linear infinite;
    mks-glitter-image {
      margin-left: 10px;
      margin-right: 0;
    }
  }
}

@keyframes slide {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
}

@keyframes slide-reverse {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

@include respond-to(XSmall) {
  .two-sliders {
    display: none;
  }
}

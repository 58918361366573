@import 'src/apps/web/styles/settings/settings.scss';
@import 'src/apps/web/styles/vendors/material/material-theme-settings';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.mat-display-1,
.mat-display-2,
.mat-display-3,
.mat-display-4 {
  color: $app-accent-base;
}

.mat-caption {
  color: $app-primary-base;
}

// NOTE: use this to put mat-dialog-actions on bottom
// <div class="dialog">whatever you want<mat-dialog-content>...</mat-dialog-content><mat-dialog-actions></mat-dialog-actions></div>
.dialog {
  height: 100%;
  display: flex;
  flex-direction: column;
  mat-dialog-content {
    flex-grow: 1;
  }
}

.no-padding-dialog {
  .mat-dialog-container {
    padding: 0px !important;
  }
}

.no-padding-form-field {
  .mat-form-field-wrapper {
    padding: 0px !important;
    padding-bottom: 0px !important;
  }
}

.mat-paginator-range-label {
  margin-left: 10px !important;
  margin-right: 10px !important;
}

mat-sidenav {
  .sidenav-sm,
  .sidenav-md,
  .sidenav-lg {
    width: $sidenav-mobile-width;

    @include media-breakpoint-up(sm) {
      padding-top: $mat-toolbar-height-desktop;
      padding-bottom: $mat-toolbar-height-desktop;
    }

    mat-toolbar,
    mat-toolbar {
      left: auto !important;
      right: auto !important;
      width: $sidenav-mobile-width !important;
    }
  }

  .sidenav-sm {
    @include media-breakpoint-up(sm) {
      width: $sidenav-sm-desktop-width;
    }

    mat-toolbar,
    mat-toolbar {
      @include media-breakpoint-up(sm) {
        width: $sidenav-sm-desktop-width !important;
      }
    }
  }

  .sidenav-md {
    @include media-breakpoint-up(sm) {
      width: $sidenav-md-desktop-width;
    }

    mat-toolbar,
    mat-toolbar {
      @include media-breakpoint-up(sm) {
        width: $sidenav-md-desktop-width !important;
      }
    }
  }

  .sidenav-lg {
    @include media-breakpoint-up(sm) {
      width: $sidenav-lg-desktop-width;
    }

    mat-toolbar,
    mat-toolbar {
      @include media-breakpoint-up(sm) {
        width: $sidenav-lg-desktop-width !important;
      }
    }
  }
}

/* mixin for multiline */
@mixin multiLineEllipsis($lineHeight: 1.2em, $lineCount: 1, $bgColor: #ffffff) {
  word-wrap: break-word;
  overflow: hidden;
  max-height: $lineHeight * $lineCount;
  line-height: $lineHeight;
  padding-right: 20px !important;
  position: relative;
  &:before {
    content: '...';
    position: absolute;
    right: 0;
    bottom: 0;
  }
  &:after {
    content: ' ';
    position: absolute;
    right: 0;
    width: 1em;
    height: 1em;
    margin-top: 0.2em;
    background: $bgColor !important;
  }
}

@function calculateRem($size) {
  $rem-size: calc($size / 16px);
  @return $rem-size * 1rem;
}

@mixin font-size($size) {
  font-size: $size;
  font-size: calculateRem($size);
}

@mixin line-height($size) {
  line-height: $size;
  line-height: calculateRem($size);
}

.b-none {
  border: none !important;
}
.cursor-pointer {
  cursor: pointer;
}

.z-1 {
  z-index: 1;
}

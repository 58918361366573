* {
  box-sizing: border-box;
  outline: none;
}
html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

*[hidden] {
  display: none !important;
}

/*--------------------------------------------------------------
# Map Mobile
--------------------------------------------------------------*/
//Variables
$dot_color: #c7c7c7;

$top_margin: 9px;
$right_margin: 2px;

$x: 0;
$y: 9px;
$rad: 0;
$width: 7px;
$height: 7px;
$full_width: calc(9 * 63px - 2px);
$m_full_width: calc(9 * 36px - 2px);
$full_height: calc(9 * 31px - 2px);
$m_full_height: calc(9 * 62px + 53px);

$type_10: $x $y $rad $dot_color;
$type_20: $x $y * 2 $rad $dot_color;
$type_30: $x $y * 3 $rad $dot_color;
$type_40: $x $y * 4 $rad $dot_color;
$type_50: $x $y * 5 $rad $dot_color;
$type_60: $x $y * 6 $rad $dot_color;
$type_70: $x $y * 7 $rad $dot_color;
$type_80: $x $y * 8 $rad $dot_color;
$type_90: $x $y * 9 $rad $dot_color;
$type_100: $x $y * 10 $rad $dot_color;
$type_110: $x $y * 11 $rad $dot_color;
$type_120: $x $y * 12 $rad $dot_color;
$type_130: $x $y * 13 $rad $dot_color;
$type_140: $x $y * 14 $rad $dot_color;
$type_150: $x $y * 15 $rad $dot_color;
$type_160: $x $y * 16 $rad $dot_color;
$type_170: $x $y * 17 $rad $dot_color;
$type_180: $x $y * 18 $rad $dot_color;
$type_190: $x $y * 19 $rad $dot_color;
$type_200: $x $y * 20 $rad $dot_color;
$type_210: $x $y * 21 $rad $dot_color;
$type_220: $x $y * 22 $rad $dot_color;
$type_230: $x $y * 23 $rad $dot_color;
$type_240: $x $y * 24 $rad $dot_color;
$type_250: $x $y * 25 $rad $dot_color;
$type_260: $x $y * 26 $rad $dot_color;
$type_270: $x $y * 27 $rad $dot_color;
$type_280: $x $y * 28 $rad $dot_color;
$type_290: $x $y * 29 $rad $dot_color;
$type_300: $x $y * 30 $rad $dot_color;

$bottom-margin: $top_margin * 25 + 50px + $right_margin;

$marker_active_top: 15.5 * $y;

//Map Construction
@include respond-to(Small-and-down) {
  .map {
    width: $m_full_width;
    height: $m_full_height;

    &__marker {
      //width: $width;
      //height: $height;
      //transform: scale(2);
      &-content {
        width: $width * 30;
        height: $height * 30;

        &.desktop {
          display: none;
        }

        &.active {
          top: $marker_active_top !important;
          left: 50% !important;
          transform: translateX(-50%) translateY(-50%) scale(1);
          opacity: 1;
          visibility: visible;
          z-index: 50;
        }
      }
    }

    &__img-wrap {
      padding: 0 5px;
    }

    &__body {
      flex-wrap: wrap;
    }

    &__break {
      flex-basis: 100%;
      display: block;
    }

    &__item {
      width: $width;
      height: $height;
      margin: 0 $right_margin 0 0;

      &:first-child {
        margin-bottom: $bottom-margin;
      }

      //Left
      &:first-child,
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(5) {
        margin-top: $top_margin * 5;
        box-shadow: $type_10, $type_20;
      }

      &:nth-child(6) {
        margin-top: $top_margin * 5;
        box-shadow: $type_10, $type_20, $type_30;
      }

      &:nth-child(7) {
        margin-top: $top_margin * 5;
        box-shadow: $type_10, $type_20, $type_30, $type_40;
      }

      &:nth-child(8) {
        margin-top: $top_margin * 4;
        box-shadow: $type_10, $type_20, $type_30, $type_40, $type_50, $type_60, $type_80;
      }

      &:nth-child(9) {
        margin-top: $top_margin * 4;
        box-shadow: $type_10, $type_20, $type_30, $type_40, $type_50, $type_60, $type_70, $type_80, $type_90;
      }

      &:nth-child(10) {
        margin-top: $top_margin * 2;
        box-shadow: $type_10, $type_20, $type_30, $type_40, $type_50, $type_60, $type_70, $type_80, $type_90,
          $type_100, $type_110, $type_120;
      }

      &:nth-child(11) {
        margin-top: $top_margin * 3;
        box-shadow: $type_10, $type_20, $type_30, $type_40, $type_50, $type_60, $type_70, $type_80, $type_90,
          $type_100, $type_110, $type_120;
      }

      &:nth-child(12) {
        margin-top: $top_margin * 6;
        box-shadow: $type_10, $type_20, $type_30, $type_40, $type_50, $type_60, $type_70, $type_80, $type_90,
          $type_100;
      }

      &:nth-child(13) {
        margin-top: $top_margin;
        box-shadow: $type_10, $type_20, $type_40, $type_50, $type_60, $type_70, $type_80, $type_90, $type_100,
          $type_110, $type_120, $type_130;
      }

      &:nth-child(14) {
        margin-top: $top_margin;
        box-shadow: $type_20, $type_40, $type_50, $type_80, $type_90, $type_100, $type_110, $type_120,
          $type_130, $type_150, $type_160;
      }

      &:nth-child(15) {
        box-shadow: $type_10, $type_20, $type_30, $type_40, $type_50, $type_60, $type_90, $type_100, $type_110,
          $type_120, $type_130, $type_140, $type_170;
      }

      &:nth-child(16) {
        box-shadow: $type_10, $type_20, $type_40, $type_70, $type_90, $type_100, $type_110, $type_120,
          $type_130, $type_180, $type_200, $type_210;
      }

      &:nth-child(17) {
        box-shadow: $type_10, $type_50, $type_60, $type_70, $type_80, $type_90, $type_100, $type_110,
          $type_120, $type_180, $type_190, $type_200, $type_210, $type_220, $type_270, $type_280, $type_290,
          $type_300;
      }

      &:nth-child(18) {
        box-shadow: $type_10, $type_20, $type_60, $type_80, $type_90, $type_100, $type_110, $type_180,
          $type_190, $type_200, $type_210, $type_220, $type_230, $type_240, $type_250, $type_260, $type_270,
          $type_280, $type_290, $type_300;
      }

      &:nth-child(19) {
        box-shadow: $type_10, $type_20, $type_80, $type_90, $type_110, $type_180, $type_190, $type_200,
          $type_210, $type_220, $type_230, $type_240, $type_250, $type_260, $type_270;
      }

      &:nth-child(20) {
        margin-top: $top_margin;
        box-shadow: $type_10, $type_20, $type_90, $type_180, $type_190, $type_200, $type_210, $type_220,
          $type_230, $type_240;
      }

      &:nth-child(21) {
        margin-top: $top_margin;
        box-shadow: $type_10, $type_20, $type_30, $type_40, $type_50, $type_180, $type_190, $type_200,
          $type_210, $type_220, $type_230, $type_240;
      }

      &:nth-child(22) {
        box-shadow: $type_10, $type_20, $type_30, $type_40, $type_50, $type_60, $type_70, $type_200, $type_210,
          $type_220, $type_230;
      }

      &:nth-child(23) {
        box-shadow: $type_10, $type_20, $type_30, $type_40, $type_50, $type_60, $type_210, $type_220,
          $type_230;
      }

      &:nth-child(24),
      &:nth-child(25) {
        box-shadow: $type_10, $type_20, $type_30, $type_40, $type_50;
      }

      &:nth-child(26) {
        box-shadow: $type_10, $type_20, $type_30, $type_60;
      }

      &:nth-child(27) {
        margin-top: $top_margin * 6;
        box-shadow: $type_110;
      }

      //Right
      &:nth-child(29) {
        margin-top: $top_margin * 15;
        box-shadow: $type_10, $type_20, $type_30;
      }

      &:nth-child(30) {
        margin-top: $top_margin * 9;
        box-shadow: $type_30, $type_40, $type_50, $type_60, $type_70, $type_80, $type_90;
      }

      &:nth-child(31) {
        margin-top: $top_margin * 9;
        box-shadow: $type_20, $type_30, $type_40, $type_50, $type_60, $type_70, $type_80, $type_90;
      }

      &:nth-child(32) {
        margin-top: $top_margin * 7;
        box-shadow: $type_30, $type_40, $type_60, $type_70, $type_80, $type_90, $type_100, $type_110;
      }

      &:nth-child(33) {
        margin-top: $top_margin * 7;
        box-shadow: $type_20, $type_30, $type_40, $type_70, $type_80, $type_90, $type_100, $type_110,
          $type_120, $type_130;
      }

      &:nth-child(34) {
        margin-top: $top_margin * 2;
        box-shadow: $type_30, $type_40, $type_50, $type_60, $type_70, $type_80, $type_90, $type_100, $type_120,
          $type_130, $type_140, $type_150, $type_160, $type_170, $type_180, $type_190, $type_200, $type_210,
          $type_220;
      }

      &:nth-child(35) {
        margin-top: $top_margin;
        box-shadow: $type_10, $type_40, $type_60, $type_80, $type_90, $type_100, $type_110, $type_130,
          $type_140, $type_150, $type_160, $type_170, $type_180, $type_190, $type_200, $type_210, $type_220,
          $type_230, $type_240;
      }

      &:nth-child(36) {
        margin-top: $top_margin * 5;
        box-shadow: $type_10, $type_20, $type_30, $type_40, $type_50, $type_60, $type_90, $type_100, $type_110,
          $type_120, $type_130, $type_140, $type_150, $type_160, $type_170, $type_180, $type_190, $type_200;
      }

      &:nth-child(37) {
        margin-top: $top_margin * 5;
        box-shadow: $type_10, $type_20, $type_30, $type_40, $type_50, $type_60, $type_70, $type_90, $type_100,
          $type_110, $type_120, $type_130, $type_140, $type_150, $type_160, $type_170, $type_180, $type_190;
      }

      &:nth-child(38) {
        margin-top: $top_margin * 7;
        box-shadow: $type_10, $type_20, $type_30, $type_40, $type_50, $type_60, $type_70, $type_80, $type_100,
          $type_110, $type_120, $type_130, $type_140, $type_150;
      }

      &:nth-child(39) {
        margin-top: $top_margin * 6;
        box-shadow: $type_10, $type_20, $type_30, $type_40, $type_50, $type_60, $type_70, $type_80, $type_90,
          $type_100, $type_110, $type_120, $type_130, $type_170, $type_180;
      }

      &:nth-child(40) {
        margin-top: $top_margin * 6;
        box-shadow: $type_10, $type_20, $type_30, $type_40, $type_70, $type_80, $type_90, $type_100, $type_120;
      }

      &:nth-child(41) {
        margin-top: $top_margin * 3;
        box-shadow: $type_30, $type_40, $type_50, $type_60, $type_70, $type_80, $type_90, $type_100, $type_110,
          $type_120, $type_130;
      }

      &:nth-child(42) {
        margin-top: $top_margin * 5;
        box-shadow: $type_10, $type_20, $type_30, $type_40, $type_50, $type_60, $type_70, $type_80, $type_90,
          $type_100;
      }

      &:nth-child(43) {
        margin-top: $top_margin * 6;
        box-shadow: $type_10, $type_20, $type_30, $type_40, $type_50, $type_60, $type_70, $type_80, $type_90;
      }

      &:nth-child(44) {
        margin-top: $top_margin * 5;
        box-shadow: $type_10, $type_20, $type_30, $type_40, $type_50, $type_60, $type_70, $type_80, $type_90,
          $type_100, $type_110;
      }

      &:nth-child(45) {
        margin-top: $top_margin * 4;
        box-shadow: $type_10, $type_20, $type_30, $type_40, $type_50, $type_60, $type_70, $type_80, $type_90,
          $type_100, $type_110, $type_120, $type_130, $type_140;
      }

      &:nth-child(46) {
        margin-top: $top_margin * 4;
        box-shadow: $type_10, $type_20, $type_30, $type_40, $type_50, $type_60, $type_70, $type_80, $type_90,
          $type_100, $type_110, $type_120;
      }

      &:nth-child(47) {
        margin-top: $top_margin * 3;
        box-shadow: $type_10, $type_20, $type_30, $type_40, $type_50, $type_60, $type_70, $type_80, $type_90,
          $type_100, $type_110, $type_120;
      }

      &:nth-child(48) {
        margin-top: $top_margin;
        box-shadow: $type_20, $type_30, $type_40, $type_50, $type_60, $type_70, $type_80, $type_90, $type_100,
          $type_110, $type_120, $type_130, $type_140, $type_150;
      }

      &:nth-child(49) {
        margin-top: $top_margin * 3;
        box-shadow: $type_10, $type_20, $type_30, $type_40, $type_50, $type_60, $type_70, $type_80, $type_90,
          $type_100, $type_110, $type_120, $type_130, $type_140, $type_160, $type_170;
      }

      &:nth-child(50) {
        margin-top: $top_margin * 3;
        box-shadow: $type_10, $type_20, $type_30, $type_40, $type_50, $type_60, $type_70, $type_80, $type_90,
          $type_100, $type_110, $type_120, $type_140;
      }

      &:nth-child(51) {
        margin-top: $top_margin * 4;
        box-shadow: $type_10, $type_20, $type_30, $type_40, $type_50, $type_60, $type_70, $type_80, $type_90,
          $type_100, $type_110, $type_160, $type_170;
      }

      &:nth-child(52) {
        margin-top: $top_margin * 4;
        box-shadow: $type_10, $type_20, $type_30, $type_40, $type_50, $type_60, $type_70, $type_80, $type_90,
          $type_100, $type_110, $type_200, $type_210, $type_220;
      }

      &:nth-child(53) {
        margin-top: $top_margin * 4;
        box-shadow: $type_10, $type_20, $type_30, $type_40, $type_50, $type_60, $type_70, $type_80, $type_190,
          $type_200, $type_210;
      }

      &:nth-child(54) {
        margin-top: $top_margin * 5;
        box-shadow: $type_10, $type_20, $type_30, $type_40, $type_50, $type_60, $type_70, $type_170, $type_180,
          $type_190, $type_200;
      }

      &:nth-child(55) {
        margin-top: $top_margin * 5;
        box-shadow: $type_10, $type_20, $type_30, $type_40, $type_50, $type_60, $type_170, $type_180,
          $type_190, $type_200;
      }

      &:nth-child(56) {
        margin-top: $top_margin * 3;
        box-shadow: $type_10, $type_20, $type_30, $type_40, $type_90, $type_180, $type_200, $type_210,
          $type_220, $type_230;
      }

      &:nth-child(57) {
        margin-top: $top_margin * 4;
        box-shadow: $type_10, $type_20, $type_30, $type_170, $type_190, $type_200, $type_210, $type_220;
      }

      &:nth-child(58) {
        margin-top: $top_margin * 5;
        box-shadow: $type_10, $type_20, $type_190, $type_200;
      }

      &:nth-child(59) {
        margin-top: $top_margin * 5;
        box-shadow: $type_10, $type_20, $type_30, $type_40;
      }

      &:nth-child(60) {
        margin-top: $top_margin * 5;
        box-shadow: $type_10;
      }

      &:nth-child(61) {
        margin-top: $top_margin * 5;
        box-shadow: $type_10, $type_20, $type_230;
      }

      &:nth-child(62) {
        margin-top: $top_margin * 5;
        box-shadow: $type_10, $type_20, $type_220;
      }

      &:nth-child(63) {
        margin-top: $top_margin * 5;
        box-shadow: $type_10;
      }

      &:nth-child(64) {
        margin-top: $top_margin * 6;
      }
    }
  }
}

@import 'layout';
@import 'navigation';
@import 'header';
@import 'two-sliders';

.container {
  @include respond-to(Small-and-down) {
    max-width: none;
  }
  @include respond-to(Medium-and-up) {
    max-width: 910px;
  }
}

.mat-table {
  border: 2px solid $app-black;

  .mat-header-row {
    background: $app-black;

    .mat-header-cell {
      color: $app-white;
    }
  }
}

.mat-row:nth-child(even) {
  background-color: rgba(245, 245, 245, 1);
}

a.mks-button {
  border: 2px solid $primary-color;
  border-radius: 0;
  padding: 14px 50px;
  margin-bottom: 50px;
  text-transform: uppercase;

  .mat-button-wrapper {
    font-size: 14px;
    line-height: 18px;
    font-weight: bold;
  }
}

.mat-sidenav {
  background-color: $primary-color !important;
}

.bg-black {
  background-color: $primary-color;
}

.left-0 {
  left: 0;
}

.right-0 {
  right: 0;
}

.pointer {
  cursor: pointer;
}

.brand-description-text {
  width: 300px;
  @include respond-to(XSmall) {
    text-align: center !important;
  }
}
.brand-detail-container {
  margin-top: -150px;
  @include respond-to(XSmall) {
    text-align: center !important;
  }
}
.button {
  display: block;
  width: 155px;
  margin: 0 auto;
  padding: 10px;
  border: 2px solid $app-black;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 1px;
  font-size: 11px !important;
  text-align: center;
  font-family: 'Futura';
  font-weight: bold;
  transition: all 0.4s ease;

  &:hover,
  &:focus {
    transition: all 0.4s ease;
    text-decoration: none;
  }

  &:focus {
    outline: 1px dashed $app-black;
  }

  &.black-btn {
    border-color: $app-black;
    color: $app-black;

    &:hover {
      background-color: $app-black;
      color: $app-white;
    }

    &:focus {
      opacity: 0.85;
      outline: 1px dashed $app-black;
    }
  }

  &.white-btn {
    border-color: $app-white;
    color: $app-white;

    &:hover {
      background-color: $app-white;
      color: $app-black;
    }

    &:focus {
      opacity: 0.85;
      outline: 1px dashed $app-white;
    }
  }
}

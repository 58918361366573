$XSmall: 'screen and (max-width: 600px)';
$Small: 'screen and (min-width: 600px) and (max-width: 960px)';
$Small-and-down: 'screen and (max-width: 960px)';
$Medium-and-down: 'screen and (max-width: 1279px)';
$Medium: 'screen and (min-width: 960px) and (max-width: 1280px)';
$Medium-and-up: 'screen and (min-width: 960px)';
$Large: 'screen and (min-width: 1280px) and (max-width: 1920px)';
$XLarge: 'screen and (min-width: 1920px)';

@mixin respond-to($alias) {
  @if $alias == 'XSmall' {
    @media #{$XSmall} {
      @content;
    }
  } @else if $alias == 'Small' {
    @media #{$Small} {
      @content;
    }
  } @else if $alias == 'Small-and-down' {
    @media #{$Small-and-down} {
      @content;
    }
  } @else if $alias == 'Medium-and-down' {
    @media #{$Medium-and-down} {
      @content;
    }
  } @else if $alias == 'Medium' {
    @media #{$Medium} {
      @content;
    }
  } @else if $alias == 'Medium-and-up' {
    @media #{$Medium-and-up} {
      @content;
    }
  } @else if $alias == 'Large' {
    @media #{$Large} {
      @content;
    }
  } @else if $alias == 'XLarge' {
    @media #{$XLarge} {
      @content;
    }
  }
}
